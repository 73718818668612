import { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import ASSETS from 'assets';
import { ICompany } from 'types/interfaces';
import QuillViewer from 'components/QuillViewer/QuillViewer';
import QuilEditor from 'components/QuillEditor/QuilEditor';
import { updateCompanyDetails } from 'http/companyService';
import { t } from 'i18next';

const CompanyDescription = () => {
  const [edit, setEdit] = useState(false);

  const dispatch = useDispatch<Dispatch>();

  const { user } = useSelector((state: RootState) => state.auth);
  const company: ICompany = user?.company?.length ? user.company[0] : null;

  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  const handleEdit = () => setEdit(true);

  const handleSaveChanges = async () => {
    try {
      setLoading(true);
      await updateCompanyDetails(
        {
          description: value,
          name: company.name,
          location: company.location,
        },
        company.company_id
      );
      setEdit(false);
      dispatch.auth.handleGetCurrentUser();
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue(company.description);
  }, [company]);

  return (
    <div className='bg-white border border-grey-600 rounded-[12px] 2xl:p-10 p-5  mt-5'>
      <div className='flex items-center justify-center gap-3'>
        <p className='2xl:text-2xl text-xl font-medium'>
          {t('Company Description')}
        </p>
        <img
          src={ASSETS.editPen}
          className='size-8 cursor-pointer'
          alt=''
          onClick={handleEdit}
        />
      </div>
      {edit ? (
        <div className='flex flex-col w-full gap-3 pt-2'>
          <QuilEditor value={value} onChange={val => setValue(val)} />
          <Button
            label={t('Save Changes')}
            className='!w-full 2xl:!text-2xl !text-lg'
            onClick={handleSaveChanges}
            loading={loading}
          />
        </div>
      ) : (
        <p className='2xl:text-22 text-base leading-7 2xl:mt-[30px] mt-4 font-normal text-placeholder'>
          <QuillViewer description={company.description} />
        </p>
      )}

      {/* {edit && <CompanyDescDialog open={edit} setOpen={setEdit} />} */}
    </div>
  );
};

export default CompanyDescription;
