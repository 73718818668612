import React, { useState, useRef, useEffect, FC } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import DialogLayout from 'Dialogs/DialogLayout';
import Button from 'components/Button/Button';
import ASSETS from 'assets';
import { googleApiKey, stateAbbreviations } from 'app.config';
import { t } from 'i18next';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (e: string) => void;
  value: string;
}

interface ILocation {
  lng: number;
  lat: number;
}

const LocationDialog: FC<IProps> = ({ open, setOpen, onChange, value }) => {
  const [location, setLocation] = useState({
    lng: -119.4179,
    lat: 36.7783,
  });
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [inputValue, setInputValue] = useState(value);
  const [predictions, setPredictions] = useState<string[]>([]);
  const autocompleteService =
    useRef<google.maps.places.AutocompleteService | null>(null);

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleApiKey || '',
    libraries: ['places'],
  });

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (isLoaded && !autocompleteService.current) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, [isLoaded]);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    // Clear predictions if input is empty
    if (!value) {
      setPredictions([]);
      return;
    }

    if (autocompleteService.current && value) {
      autocompleteService.current.getPlacePredictions(
        {
          input: value,
          types: ['(cities)'],
          componentRestrictions: { country: 'us' },
        },
        async (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            const list: string[] = [];
            const loc: ILocation[] = [];

            // Map predictions to a list of promises to get details
            const detailPromises = predictions.map(prediction => {
              const placeId = prediction.place_id;
              return new Promise<void>(resolve => {
                const placesService =
                  new window.google.maps.places.PlacesService(map!);
                placesService.getDetails({ placeId }, (place, status) => {
                  if (
                    status ===
                      window.google.maps.places.PlacesServiceStatus.OK &&
                    place?.geometry?.location
                  ) {
                    const addressComponents = place.address_components;
                    const l: ILocation = {
                      lat: place.geometry.location.lat(),
                      lng: place.geometry.location.lng(),
                    };
                    if (addressComponents) {
                      const city =
                        addressComponents.find(component =>
                          component.types.includes('locality')
                        )?.long_name || '';

                      const state =
                        addressComponents.find(component =>
                          component.types.includes(
                            'administrative_area_level_1'
                          )
                        )?.long_name || '';

                      if (city && stateAbbreviations[state]) {
                        const address = `${city}, ${stateAbbreviations[state]}`;
                        if (!list.includes(address)) {
                          list.push(address);
                          loc.push(l);
                        }
                      }
                    }
                  }
                  resolve();
                });
              });
            });

            // Wait for all details requests to complete
            await Promise.all(detailPromises);

            // After all requests complete, update the predictions list
            setPredictions(list);
            setLocations(loc);
          } else {
            setPredictions([]);
          }
        }
      );
    }
  };

  const getLocationDetails = (lat: number, lng: number) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK' && results) {
        const addressComponents = results[0].address_components;
        const city =
          addressComponents.find(component =>
            component.types.includes('locality')
          )?.long_name || '';
        const state =
          addressComponents.find(component =>
            component.types.includes('administrative_area_level_1')
          )?.long_name || '';

        if (city && stateAbbreviations[state]) {
          const address = `${city}, ${stateAbbreviations[state]}`;
          setInputValue(address);
          onChange(address);
        }
        // You can update state with city, state, and country as needed
      } else {
        console.error('Geocoder failed due to: ' + status);
      }
    });
  };

  const handleSelectPrediction = (prediction: string, index: number) => {
    setInputValue(prediction);
    onChange(prediction);
    setLocation(locations[index]);
    setPredictions([]);
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      setLocation({ lat, lng });
      getLocationDetails(lat, lng); // Fetch location details based on clicked point
      setPredictions([]);
      setLocations([]);
    }
  };

  useEffect(() => {
    console.log(predictions);
  }, [predictions]);

  return (
    <DialogLayout open={open}>
      <div className='w-full max-w-[689px] rounded-[16px] bg-white aspect-[1/0.88] min-h-[400px] flex flex-col overflow-hidden'>
        <div className='flex-grow relative'>
          {isLoaded && (
            <>
              <GoogleMap
                center={location}
                zoom={0}
                mapContainerStyle={{
                  width: '100%',
                  height: '100%',
                }}
                options={{
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                  restriction: {
                    latLngBounds: {
                      north: 49.38,
                      south: 24.52,
                      west: -125.0,
                      east: -66.93,
                    },
                    strictBounds: true,
                  },
                }}
                onLoad={map => setMap(map)}
                onClick={handleMapClick}
              >
                <Marker
                  visible
                  position={{ lat: location.lat, lng: location.lng }}
                />
              </GoogleMap>
              <div className='search w-[calc(100%_-_80px)] absolute left-[50%] top-[27px] translate-x-[-50%] flex items-center gap-3'>
                <div
                  className='h-[50px] w-[50px] rounded-full grid place-items-center  bg-grey-400 cursor-pointer border border-grey-400'
                  role='button'
                  onClick={handleClose}
                >
                  <img src={ASSETS.chevronLeft} alt='' />
                </div>
                <div className='h-[50px] flex-grow  border border-grey-400 flex items-center gap-2 bg-white relative rounded-lg focus-within:border-secondary has-[]:'>
                  <img
                    src={ASSETS.chevronDown}
                    className='absolute top-[50%] translate-y-[-50%] right-[15px]'
                    alt=''
                  />
                  <input
                    type='text'
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder={t('Search City or State')}
                    className='flex-grow outline-none px-4 h-full bg-transparent text-base'
                  />
                  {predictions.length > 0 && (
                    <ul className='absolute w-full bg-white border border-gray-300 mt-1 rounded-md z-10 divide-y bottom-0 translate-y-[calc(100%_+_10px)]'>
                      {predictions.map((prediction, index) => (
                        <li
                          key={prediction}
                          className='cursor-pointer p-2 hover:bg-gray-100'
                          onClick={() =>
                            handleSelectPrediction(prediction, index)
                          }
                        >
                          {prediction}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                {/* Custom Autocomplete Dropdown */}
              </div>
              <img
                src={ASSETS.mapButton}
                className='absolute right-0 bottom-0 rounded-full cursor-pointer'
                alt=''
                onClick={() => map?.panTo(location)}
              />
            </>
          )}
        </div>
        <div className='flex items-center gap-6 justify-center p-5 flex-shrink-0 min-h-[89px]'>
          <Button
            label={t('Confirm')}
            className='min-w-[213px] w-full !text-base'
            onClick={handleClose}
            disabled={!inputValue}
          />
        </div>
      </div>
    </DialogLayout>
  );
};

export default LocationDialog;
