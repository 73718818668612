import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import Accordion from './Accordion';
import ASSETS from 'assets';
import { t } from 'i18next';

const OPTIONS = ['Newest', 'Most Viewed', 'Most Saved'];

const SortBy = () => {
  const dispatch = useDispatch<Dispatch>();
  const { sortBy } = useSelector((state: RootState) => state.filters);

  const handleSelect = (op: string) => {
    dispatch.filters.setSortBy(op);
  };

  return (
    <Accordion label={'Sort by'}>
      <div className='px-2 pb-2 flex flex-col gap-1'>
        {OPTIONS.map((option, index) => (
          <div
            key={index}
            className='flex items-center gap-2 cursor-pointer'
            onClick={() => handleSelect(option)}
          >
            <img
              src={sortBy === option ? ASSETS.circleFilled : ASSETS.circle}
              className='w-5 h-5'
              alt=''
            />
            <p className='text-sm'>{t(option)}</p>
          </div>
        ))}
      </div>
    </Accordion>
  );
};

export default SortBy;
