import React, { FC, createContext, useEffect } from 'react';

import AC from 'agora-chat';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { agoraKey } from '../app.config';

const connection = new AC.connection({
  appKey: '411188923#1376357',
});

export const AgoraContext = createContext(connection);

interface IProps {
  children: React.ReactNode;
}

const AgoraProvider: FC<IProps> = ({ children }) => {
  const { user, agoraToken } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (connection && user && agoraToken) {
      connection
        .open({
          user: user.agora_user_name!,
          agoraToken: agoraToken,
        })
        .then(() => {
          console.log('Agora connection opened');
        })
        .catch(err => console.log('Error in connection ======', err));
    }
    return () => {
      if (connection.isOpened()) {
        connection.close();
      }
    };
    // eslint-disable-next-line
  }, [user, connection, agoraToken]);

  return (
    <AgoraContext.Provider value={connection}>{children}</AgoraContext.Provider>
  );
};

export default AgoraProvider;
