import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { useNavigate } from 'react-router-dom';

import ASSETS from 'assets';
import { introQuestions } from 'app.config';
import { toast } from 'react-toastify';
import { deleteVideo } from 'http/videoService';
import { t } from 'i18next';

const VideoIntro = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { user } = useSelector((state: RootState) => state.auth);

  const handleDeleteVideo = async () => {
    try {
      dispatch.utils.setActionLoading(true);
      if (user?.introVideos?.length) {
        await deleteVideo(user?.introVideos[0].id);
        dispatch.auth.handleGetCurrentUser();
        toast.success('Video deleted successfully');
        dispatch.utils.closeActionDialog();
      }
    } catch (err: any) {
      console.log(err.message);
      toast.error('Something went wrong');
    } finally {
      dispatch.utils.setActionLoading(false);
    }
  };

  const handleDelete = () => {
    dispatch.utils.openActionDialog({
      title: t('Delete Video'),
      message: t('Are you sure you want to delete this video.'),
      buttonTitle: t('Delete'),
      onClick: () => {
        handleDeleteVideo();
      },
      open: true,
    });
  };

  const handleIntroVideo = () => {
    dispatch.utils.setPath('profile');
    dispatch.video.setData(introQuestions);
    navigate('/create-video');
  };
  return (
    <div
      className='bg-white border border-grey-600 rounded-[12px] 2xl:p-12 p-5  
flex flex-col items-center'
    >
      <div className='flex items-center gap-3'>
        <p className='2xl:text-2xl text-xl font-medium'>
          {t('Video Introduction')}
        </p>
        {user?.introVideos && user?.introVideos?.length > 0 && (
          <div className='w-6 h-6 rounded-full border border-secondary grid place-items-center'>
            <img
              src={ASSETS.delete}
              className='w-[14px] cursor-pointer'
              alt=''
              onClick={handleDelete}
            />
          </div>
        )}
      </div>
      <div className='w-full 2xl:max-w-[416px] max-w-[300px] h-[320px] 2xl:h-[470px] rounded-[10px] relative 2xl:mt-5 mt-2'>
        {user?.introVideos && user?.introVideos?.length > 0 ? (
          <video
            src={user?.introVideos[0].url}
            poster={user?.introVideos[0].thumbnail}
            className='w-full h-full object-contain rounded-[10px] bg-black'
            controls
          ></video>
        ) : (
          <div
            className='w-full h-full border border-dashed border-placeholder rounded-[12px] bg-grey-100 flex justify-center items-center cursor-pointer'
            onClick={handleIntroVideo}
          >
            <img
              src={ASSETS.plusCircle}
              className='cursor-pointer w-10'
              alt=''
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoIntro;
