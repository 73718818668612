import {
  IApiResponse,
  IOnboading,
  IResendCode,
  IResetPassword,
  ISignUp,
  IUserWithTokens,
} from 'types/api';
import api from './index';

import { IUser } from 'types/interfaces';

const apiKey = 'final_round@LqA[-tUCY]na)(1,M1-EQpxFLZcYS9';

export const onBoarding = (data: IOnboading) =>
  api.post<IApiResponse<{ token: string }>>('/v1/auth/verify', data, {
    headers: {
      'X-API-KEY': apiKey,
    },
  });

export const phoneVerification = (data: { token: string; code: string }) =>
  api.post<IApiResponse<IUserWithTokens>>(
    `/v1/auth/phone-verification/${data.token}`,
    { code: data.code },
    {
      headers: {
        'X-API-KEY': apiKey,
      },
    }
  );

export const updateInfo = (data: ISignUp, token: string) =>
  api.patch('/v1/auth/info', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const login = (data: { email: string; password: string }) =>
  api.post<IApiResponse<IUserWithTokens>>('/v1/auth/login', data, {
    headers: {
      'X-API-KEY': apiKey,
    },
  });

export const verifyEmail = (data: { token: string; code: string }) =>
  api.post<IApiResponse<IUserWithTokens>>(
    `v1/auth/email-verification/${data.token}`,
    { code: data.code },
    {
      headers: {
        'X-API-KEY': apiKey,
      },
    }
  );

export const updateMobile = (data: { mobile: string }, token: string) =>
  api.patch<IApiResponse<IUser>>('/v1/auth/phone', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const resendCode = (data: IResendCode) =>
  api.post<IApiResponse<{ token: string }>>('/v1/auth/code', data, {
    headers: {
      'X-API-KEY': apiKey,
    },
  });

export const forgorPassword = (email: string) =>
  api.post<IApiResponse<{ token: string }>>(
    '/v1/auth/forgot-password',
    {
      email,
    },
    {
      headers: {
        'X-API-KEY': apiKey,
      },
    }
  );

export const verifyForgotPasswordOtp = (code: string, token: string) =>
  api.post<IApiResponse<{}>>(
    `/v1/auth/verify/recover-password/${token}`,
    {
      code,
    },
    {
      headers: {
        'X-API-KEY': apiKey,
      },
    }
  );

export const resetPassword = (data: IResetPassword, token: string) =>
  api.post(`/v1/auth/recover-password/${token}`, data, {
    headers: {
      'X-API-KEY': apiKey,
    },
  });

export const isPhoneExist = (data: { mobile: string }) =>
  api.post<IApiResponse<{ isMobileExists: boolean }>>(
    '/v1/auth/verify/mobile',
    data,
    {
      headers: {
        'X-API-KEY': apiKey,
      },
    }
  );

export const isEmailExist = (data: { email: string }) =>
  api.post<IApiResponse<{ isEmailExists: boolean }>>(
    '/v1/auth/verify/email',
    data,
    {
      headers: {
        'X-API-KEY': apiKey,
      },
    }
  );

export const forgotEmail = (data: { mobile: string }) =>
  api.post<IApiResponse<{ phone_confirmation_token: string }>>(
    '/v1/auth/recover-email/code',
    data,
    {
      headers: {
        'X-API-KEY': apiKey,
      },
    }
  );

export const getEmailFromToken = (code: string, token: string) =>
  api.post<IApiResponse<{ email: string }>>(
    `/v1/auth/recover-email/${token}`,
    { code },
    {
      headers: {
        'X-API-KEY': apiKey,
      },
    }
  );

export const getCurrentUser = () => api.get<IApiResponse<IUser>>('/v1/user');

export const getUserById = (id: string) =>
  api.get<IApiResponse<IUser>>(`/v1/user/${id}`);

export const chatAuth = () => api.post('/v1/auth/chat');

export const updateDeviceToken = (token: string) =>
  api.patch('/v1/user/device-token', { device_token: token });

export const getChatToken = () =>
  api.get<IApiResponse<string>>('/v1/chat/token');
