import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { object, string, ref, InferType } from 'yup';

import TextField from 'components/TextField/TextField';
import ASSETS from 'assets';
import Button from 'components/Button/Button';
import { isEmailExist } from 'http/authService';
import { t } from 'i18next';

const schema = object({
  first_name: string().required('Required field').label('First Name'),
  last_name: string().required('Required field').label('Last Name'),
  email: string()
    .email('Invalid Email')
    .required('Required field')
    .label('Email'),
  password: string()
    .min(8, 'Must be 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[\W_]/, 'Password must contain at least one special character')
    .required('Required field')
    .label('Password'),
  confirm_password: string()
    .oneOf([ref('password'), ''], 'Passwords must match')
    .required(''),
});

interface ISignupData extends InferType<typeof schema> {}

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { authType, loading } = useSelector((state: RootState) => state.auth);

  const formik = useFormik<ISignupData>({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: schema,
    onSubmit: async values => {
      try {
        dispatch.auth.setLoading(true);
        const { data } = await isEmailExist({ email: values.email });
        if (data.data.isEmailExists) {
          formik.setErrors({ email: 'Email already exists' });
        } else {
          if (authType === 'P') {
            dispatch.auth.HandleUpdateInfo({
              values: values,
              navigate,
            });
          }
          if (authType === 'E') {
            dispatch.auth.handleSignUpWithEmail({
              ...values,
              type: 'E',
            });
          }
        }
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
      // dispatch.utils.setemailVerification({
      //   open: true,
      //   type: 'email',
      // });
    },
  });

  const handleBack = () => navigate(-1);

  return (
    <Container className='bg-grey-100 2xl:p-6 2xl:pb-[100px] p-5'>
      <BackButton className='bg-gray-300 cursor-pointer' onClick={handleBack}>
        <img src={ASSETS.chevronLeft} alt='' />
      </BackButton>
      <p className='2xl:text-32 text-2xl text-primary font-medium 2xl:mt-[35px] 2xl:mb-[45px] mt-[20px] mb-[30px]'>
        {authType === 'P' ? t('Account Setup') : t('Create Account')}
      </p>

      <Form onSubmit={e => e.preventDefault()} className='2xl:gap-[33px] gap-6'>
        <div className='grid grid-cols-2 2xl:gap-6 gap-4'>
          <TextField
            placeholder={t('First Name')}
            name='first_name'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.first_name}
            error={
              formik.touched.first_name && formik.errors.first_name
                ? formik.errors.first_name
                : ''
            }
          />
          <TextField
            placeholder={t('Last Name')}
            name='last_name'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.last_name}
            error={
              formik.touched.last_name && formik.errors.last_name
                ? formik.errors.last_name
                : ''
            }
          />
        </div>
        <TextField
          placeholder={t('Email')}
          type='email'
          name='email'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ''
          }
        />
        <TextField
          placeholder={t('Password')}
          type='password'
          name='password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={
            formik.touched.password && formik.errors.password
              ? formik.errors.password
              : ''
          }
        />
        <TextField
          placeholder={t('confirmPassword')}
          type='password'
          name='confirm_password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirm_password}
          error={
            formik.touched.confirm_password && formik.errors.confirm_password
              ? formik.errors.confirm_password
              : ''
          }
        />
        <Button
          label={authType === 'P' ? t('Continue') : t('Sign Up')}
          className='mt-2 2xl:!text-xl !text-lg'
          type='submit'
          onClick={() => formik.handleSubmit()}
          loading={loading}
        />
      </Form>
      {authType === 'E' && (
        <p className='text-base font-medium text-center mt-[14px]'>
          {t('Already have an account?')}{' '}
          <Link to='/sign-in'>
            <span className='text-blue'>{t('Sign In')}</span>
          </Link>
        </p>
      )}
    </Container>
  );
};

export default Signup;

const Container = styled.div`
  width: 100%;
  max-width: 484px;
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const BackButton = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  display: grid;
  place-items: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
