import React from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'store';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import ASSETS from 'assets';

dayjs.extend(relativeTime);

const Notifications = () => {
  const { read } = useSelector((state: RootState) => state.notifications);

  return (
    <div className='flex-grow  overflow-auto'>
      <div className='max-w-[821px] w-full bg-white border border-grey-400 rounded shadow-mm'>
        <div className='header 2xl:px-7 p-5 pb-5 2xl:pt-6'>
          <p className='2xl:text-32 text-2xl text-primary'>Notifications</p>
        </div>
        <div className='read'>
          <p className='text-base font-medium text-placeholder 2xl:px-7 px-5'>
            Read
          </p>
        </div>
        <div className='notifications'>
          {read.map(n => (
            <div
              key={n.id}
              className='flex items-center gap-[11px] 2xl:py-[18px] py-3 2xl:px-7 px-5 border-b border-b-grey-400'
            >
              <div className='2xl:size-10 size-8 rounded-full grid place-items-center bg-primary'>
                <img
                  src={ASSETS.bookmarkWhite}
                  className='w-3 2xl:w-auto'
                  alt=''
                />
              </div>
              <div>
                <p className='2xl:text-lg text-base font-medium'>{n.message}</p>
                <p className='2xl:text-base text-sm font-medium text-placeholder'>
                  {dayjs(n.created_at).fromNow()}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
