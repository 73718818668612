import React, { useState } from 'react';
import classNames from 'classnames';

import styled from 'styled-components';
import Peoples from './components/Peoples';
import Companies from './components/Companies';
import Jobs from './components/Jobs';
import { t } from 'i18next';

type TAB = 'people' | 'companies' | 'jobs';

const SearchFinds = () => {
  const [tab, setTab] = useState<TAB>('people');

  const handleTab = (tab: TAB) => setTab(tab);

  return (
    <Container className='flex flex-grow flex-col items-center'>
      <Tabs className='flex items-center gap-[9px]'>
        <div
          className={classNames(
            'tab h-10 rounded-3xl  w-[175px] flex items-center justify-center cursor-pointer border border-grey-600 transition',
            tab === 'people' ? 'border-0 bg-primary text-white' : 'bg-white'
          )}
          onClick={() => handleTab('people')}
        >
          <p className='text-sm font-medium text-inherit'>{t('People')}</p>
        </div>
        <div
          className={classNames(
            'tab h-10 rounded-3xl  w-[175px] flex items-center justify-center cursor-pointer border border-grey-600 transition',
            tab === 'companies' ? 'border-0 bg-primary text-white' : 'bg-white'
          )}
          onClick={() => handleTab('companies')}
        >
          <p className='text-sm font-medium  text-inherit'>{t('Companies')}</p>
        </div>
        <div
          className={classNames(
            'tab h-10 rounded-3xl  w-[175px] flex items-center justify-center cursor-pointer border border-grey-600 transition',
            tab === 'jobs' ? 'border-0 bg-primary text-white' : 'bg-white'
          )}
          onClick={() => handleTab('jobs')}
        >
          <p className='text-sm font-medium  text-inherit'>{t('Jobs')}</p>
        </div>
      </Tabs>
      <div className='w-full flex-grow overflow-auto h-0 '>
        {tab === 'people' && <Peoples />}
        {tab === 'companies' && <Companies />}
        {tab === 'jobs' && <Jobs />}
      </div>
    </Container>
  );
};

export default SearchFinds;

const Container = styled.div``;

const Tabs = styled.div``;
