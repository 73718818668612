import React, { FC, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import DialogLayout from 'Dialogs/DialogLayout';
import ASSETS from 'assets';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import Button from 'components/Button/Button';
import { t } from 'i18next';

const regex =
  /^\+?[1-9]\d{1,2}[ .-]?\(?\d+\)?([ .-]?\d+)*([ ]?(ext|x|extension)\s?\d+)?$/;

interface IProps {}

const ForgotEmail: FC<IProps> = props => {
  const dispatch = useDispatch<Dispatch>();

  const { forgotEmail } = useSelector((state: RootState) => state.utils);

  const { loading } = useSelector((state: RootState) => state.auth);

  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');

  const handleClose = () => {
    dispatch.utils.setForgotEmail(false);
  };

  const handleNext = () => {
    if (regex.test(phone)) {
      dispatch.auth.handleForgotEmail({ phone, setError });
    } else {
      setError('This phone number doesn’t exist');
    }
  };

  return (
    <DialogLayout open={forgotEmail}>
      <Container className='2xl:max-w-[484px] max-w-[420px] 2xl:py-[26px] 2xl:px-7 p-5'>
        <BackButton
          className='cursor-pointer bg-grey-400 '
          onClick={handleClose}
        >
          <img src={ASSETS.chevronLeft} alt='' />
        </BackButton>
        <p className='2xl:text-32 text-2xl text-primary font-medium 2xl:mt-[30px] mt-5 mb-[10px]'>
          {t('Forgot Email')}
        </p>
        <p className='2xl:text-lg text-base text-placeholder leading-5'>
          {t('Please enter the Phone number associated with your account.')}
        </p>
        <div className='mt-6 2xl:mb-14 mb-8 relative'>
          <PhoneInput phone={phone} setPhone={setPhone} error={error} />
          {/* <div className='absolute bottom-[-20px] flex items-center gap-1'>
            <img src={ASSETS.errorInfo} alt='' />
            <p className='text-xs text-error'>Lorem inspum</p>
          </div> */}
        </div>
        <Button
          label={t('Next')}
          className='2xl:!text-xl !text-lg w-full'
          onClick={handleNext}
          loading={loading}
        />
      </Container>
    </DialogLayout>
  );
};

export default ForgotEmail;

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors['grey-100']};
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px #00000040;
`;

const BackButton = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  display: grid;
  place-items: center;
`;
