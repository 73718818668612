import React, { FC } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import DialogLayout from 'Dialogs/DialogLayout';
import ASSETS from 'assets';
import TextField from 'components/TextField/TextField';
import Button from 'components/Button/Button';
import { t } from 'i18next';

const schema = object({
  email: string().email().required('Required field').label('Email'),
});

interface IProps {}

const ForgotPassword: FC<IProps> = props => {
  const dispatch = useDispatch<Dispatch>();

  const { forgotPassword } = useSelector((state: RootState) => state.utils);
  const { loading } = useSelector((state: RootState) => state.auth);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: values => {
      dispatch.auth.handleForgotPassword({ email: values.email, formik });
    },
    validationSchema: schema,
  });

  const handleClose = () => {
    dispatch.utils.setemailVerification({
      open: false,
      type: 'forgot-password',
    });
  };

  return (
    <DialogLayout open={forgotPassword}>
      <Container className='2xl:max-w-[484px] max-w-[420px] 2xl:py-[26px] 2xl:px-7 p-5'>
        <BackButton
          className='cursor-pointer bg-grey-400'
          onClick={handleClose}
        >
          <img src={ASSETS.chevronLeft} alt='' />
        </BackButton>
        <p className='2xl:text-32 text-2xl 2xl:mt-[30px] mt-5 mb-[10px] text-primary font-medium'>
          {t('Forgot Password')}
        </p>
        <p className='2xl:text-lg text-base text-placeholder'>
          {t('Please enter the email associated with your account.')}
        </p>
        <TextField
          className='2xl:mt-10 2xl:mb-11 my-7'
          placeholder={t('Email')}
          name='email'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.errors.email}
        />
        <Button
          className='w-full 2xl:!text-xl !text-lg'
          label={t('Send Code')}
          type='submit'
          onClick={() => formik.handleSubmit()}
          loading={loading}
        />
        <p className='2xl:text-base text-sm text-center 2xl:mt-5 mt-4 font-semibold'>
          {t('Remember Password?')}{' '}
          <span className='text-blue cursor-pointer' onClick={handleClose}>
            {t('Sign In')}
          </span>
        </p>
      </Container>
    </DialogLayout>
  );
};

export default ForgotPassword;

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors['grey-100']};
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px #00000040;
`;

const BackButton = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  display: grid;
  place-items: center;
`;
