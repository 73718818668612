import { FC, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import ClickAwayListener from 'react-click-away-listener';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ASSETS from 'assets';

const Profile: FC = () => {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);

  const dispatch = useDispatch<Dispatch>();

  const [t] = useTranslation();

  const { user } = useSelector((state: RootState) => state.auth);

  const toggleMenu = () => setOpenMenu(!openMenu);
  const closeMenu = () => setOpenMenu(false);

  const handleProfileClick = () => {
    const r = user?.role;
    const details = user?.userDetails || {};
    if (!user?.role) {
      closeMenu();
      return dispatch.utils.setRoleModal(true);
    }
    if (r === 'A' || r === 'RA' || r === 'SA') {
      if (!user?.introVideos?.length) {
        navigate('/create-video');
      } else if (!user?.job_title) {
        navigate('/make-profile/step-1');
      } else if (!Object.keys(details).length) {
        navigate('/make-profile/step-4');
      } else {
        navigate('/profile');
      }
    } else {
      if (!user.company?.length) {
        dispatch.companies.setSelected(null);
        navigate('/create-company');
      } else {
        navigate('/profile');
      }
    }
    closeMenu();
  };
  return (
    <Container className='relative'>
      <div
        className='menu-opener 2xl:size-[45px] size-9  rounded-full cursor-pointer'
        onClick={toggleMenu}
      >
        <img
          src={user?.image_url || ASSETS.preview}
          alt=''
          className='w-9 aspect-square 2xl:w-[45px] rounded-full object-cover'
        />
      </div>
      {openMenu && (
        <ClickAwayListener onClickAway={closeMenu}>
          <div className='menu absolute w-[229px] rounded-[8px] right-0 bg-white  flex flex-col gap-[2px] overflow-hidden bottom-0 translate-y-[calc(100%_+_5px)]'>
            <div
              className='pl-5 pr-2 flex items-center gap-6 py-3 hover:bg-grey-100 cursor-pointer'
              onClick={handleProfileClick}
            >
              <img src={ASSETS.user} alt='' />
              <p className='text-base font-medium'>{t('Profile')}</p>
            </div>
            <div
              className='pl-5 pr-2 flex items-center gap-6 py-3 hover:bg-grey-100 cursor-pointer'
              onClick={() => {
                navigate('/settings');
                closeMenu();
              }}
            >
              <img src={ASSETS.settings} className='w-[22px]' alt='' />
              <p className='text-base font-medium'>{t('Settings')}</p>
            </div>
            <div
              className='pl-5 pr-2 flex items-center gap-6 py-3 hover:bg-grey-100 cursor-pointer'
              onClick={() => {
                navigate('/search-preferences');
                closeMenu();
              }}
            >
              <img src={ASSETS.bars} alt='' />
              <p className='text-base font-medium'>{t('Search Preferences')}</p>
            </div>
            <div
              className='pl-5 pr-2 flex items-center gap-6 py-3 hover:bg-grey-100 cursor-pointer'
              onClick={() => {
                navigate('/premium');
                closeMenu();
              }}
            >
              <img src={ASSETS.premium} alt='' />
              <p className='text-base font-medium'>{t('Get Premium')}</p>
            </div>
            {/* <div
              className='pl-5 pr-2 flex items-center gap-6 py-3 hover:bg-grey-100 cursor-pointer'
              onClick={closeMenu}
            >
              <img src={ASSETS.logout} alt='' />
              <p className='text-base font-medium'>Log Out</p>
            </div> */}
          </div>
        </ClickAwayListener>
      )}
    </Container>
  );
};

export default Profile;

const Container = styled.div`
  & .menu {
    box-shadow: 0px 3.51px 10.53px 0px #0000004d;
  }
`;
