import { createModel } from '@rematch/core';
import { RootModel } from '.';

type ListFilters =
  | 'location'
  | 'jobTitle'
  | 'tags'
  | 'jobLevel'
  | 'companies'
  | 'areaOfExperties';

interface IState {
  sortBy: string;
  location: string[];
  jobTitle: string[];
  tags: string[];
  jobLevel: string[];
  companies: string[];
  areaOfExperties: string[];
  min: number;
  max: number;
  filterApplied: number;
}

export const filters = createModel<RootModel>()({
  name: 'filters',
  state: {
    sortBy: 'Newest',
    location: [],
    jobTitle: [],
    tags: [],
    jobLevel: [],
    companies: [],
    areaOfExperties: [],
    min: 10,
    max: 500,
    filterApplied: 0,
  } as IState,
  reducers: {
    setListFilters(state, payload: { name: ListFilters; value: string }) {
      const list = state[payload.name];
      if (list.includes(payload.value)) {
        const index = list.indexOf(payload.value);
        list.splice(index, 1);
      } else {
        list.push(payload.value);
      }
      state[payload.name] = list;
    },
    setSalaryRangeFilter(state, payload: [number, number]) {
      state.min = payload[0];
      state.max = payload[1];
    },
    setSortBy(state, payload: string) {
      state.sortBy = payload;
    },
    reset() {
      return {
        sortBy: '',
        location: [],
        jobTitle: [],
        tags: [],
        jobLevel: [],
        companies: [],
        areaOfExperties: [],
        min: 10,
        max: 500,
        filterApplied: 0,
      };
    },
    setFilterApplied(state) {
      state.filterApplied = state.filterApplied + 1;
    },
  },
});
