import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import styled from 'styled-components';
import NotFound from './NotFound';
import ASSETS from 'assets';
import { useEffect } from 'react';
import { ISavedUser } from 'types/interfaces';
import { t } from 'i18next';

dayjs.extend(relativeTime);

const SavedProfiles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { savedProfiles } = useSelector((state: RootState) => state.myjobs);

  const handleClick = (id: string) => {
    navigate(`/other-job-seeker/${id}`);
  };

  useEffect(() => {
    dispatch.myjobs.handleGetSavedProfiles();
  }, []);

  return (
    <Container className='2xl:pt-[33px] pt-5'>
      <JobsSection>
        {savedProfiles.length === 0 && (
          <NotFound
            message={t("You don't have any saved profiles yet")}
            icon={ASSETS.saved}
          />
        )}
        {savedProfiles.map((profile: ISavedUser, i) => (
          <Job
            key={i}
            className='rounded-lg 2xl:gap-[29px] gap-4 shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white cursor-pointer'
            onClick={() => handleClick(profile.id)}
          >
            <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
              <img src={profile.image_url || ASSETS.preview} alt='' />
            </div>
            <div>
              <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                {profile.first_name} {profile.last_name}
              </p>
              <p className='2xl:text-lg text-base font-medium leading-6'>
                {profile.location}
              </p>
              <p className='2xl:text-base text-sm'>
                {dayjs(profile.created_at).fromNow()}
              </p>
            </div>
          </Job>
        ))}
      </JobsSection>
    </Container>
  );
};

export default SavedProfiles;

const Container = styled.div`
  max-width: 820px;
  width: 100%;
  margin-inline: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const JobsSection = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Job = styled.div`
  display: flex;
  align-items: center;
`;
