import React, { useCallback, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import { getCompaniesByName } from 'http/companyService';
import { ICompany } from 'types/interfaces';
import Accordion from './Accordion';

import debounce from 'utils';
import ASSETS from 'assets';

const CompanyName = () => {
  const dispatch = useDispatch<Dispatch>();

  const [search, setSearch] = useState('');
  const [companies, setCompanies] = useState<ICompany[]>([]);

  const { companies: selected } = useSelector(
    (state: RootState) => state.filters
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const fetchCompanies = async (search: string) => {
    try {
      const { data } = await getCompaniesByName(search);
      setCompanies(data.data.companies);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  // eslint-disable-next-line
  const debouncedFetchCompanies = useCallback(
    debounce((searchTerm: string) => fetchCompanies(searchTerm), 300),
    []
  );

  const handleFetchCompanies = (search: string) => {
    debouncedFetchCompanies(search);
  };

  const handleSelect = (op: string) => {
    dispatch.filters.setListFilters({ name: 'companies', value: op });
    setSearch('');
  };

  useEffect(() => {
    handleFetchCompanies(search);

    // eslint-disable-next-line
  }, [search]);

  return (
    <Accordion label='Company Name'>
      <div className='px-2 pb-2 flex flex-col gap-1'>
        <div className='flex items-center gap-1 flex-wrap'>
          {selected.map(s => (
            <div className='bg-secondary px-[7px] h-7 flex items-center rounded'>
              <p className='text-[10px] font-medium'>{s}</p>
              <img
                src={ASSETS.cross}
                className='w-3 h-3 cursor-pointer'
                onClick={() => handleSelect(s)}
                alt=''
              />
            </div>
          ))}
        </div>

        <div className='border border-grey-400 h-9 rounded-md bg-white overflow-hidden relative'>
          <input
            type='search'
            name=''
            placeholder='Search'
            className='w-full h-full text-sm outline-none border-none pl-5 pr-3'
            id=''
            value={search}
            onChange={handleChange}
          />
          <div className='absolute left-0 top-0 h-full w-[20px] aspect-square grid place-items-center'>
            <img src={ASSETS.search} className='w-4' alt='' />
          </div>
        </div>
        <div className='flex flex-col rounded bg-white max-h-[300px] empty:hidden overflow-auto'>
          {companies.map(c => (
            <div
              key={c.id}
              className='flex items-center py-2 gap-2 px-2 border-b border-b-grey-400
                  last-of-type:border-b-0
                  '
              onClick={() => handleSelect(c.name)}
            >
              <img
                src={
                  selected.includes(c.name)
                    ? ASSETS.checkboxCheck
                    : ASSETS.checkbox
                }
                className='w-5'
                alt=''
              />
              <div>
                <p className='text-sm'>{c.name}</p>
                <p className='text-[12px] text-grey-600'>{c.location}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Accordion>
  );
};

export default CompanyName;
