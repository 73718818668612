import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { INotification } from 'types/interfaces';
import { getNotifications, markAsRead } from 'http/notificationService';

interface IState {
  loading: boolean;
  read: INotification[];
  unread: INotification[];
  unreadCound: number;
}

export const notifications = createModel<RootModel>()({
  name: 'notifications',
  state: {
    loading: false,
    read: [],
    unread: [],
    unreadCound: 0,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setUnread(state, payload: INotification[]) {
      state.unread = payload;
    },
    setRead(state, payload: INotification[]) {
      state.read = payload;
    },
    setUnreadCount(state, payload: number) {
      state.unreadCound = payload;
    },
  },
  effects: dispatch => ({
    async handleGetNotifications() {
      try {
        dispatch.notifications.setLoading(true);
        const { data } = await getNotifications();
        dispatch.notifications.setUnreadCount(data.data.unread_count);
        dispatch.notifications.setRead(data.data.readNotifications);
        dispatch.notifications.setUnread(data.data.unreadNotifications);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.notifications.setLoading(false);
      }
    },
    async handleMarkAllAsRead(_, state) {
      const unread = state.notifications.unread;
      try {
        dispatch.notifications.setLoading(true);
        const markReadPromise = unread.map(n => markAsRead(n.id));
        await Promise.all(markReadPromise);
        dispatch.notifications.setUnreadCount(0);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.notifications.setLoading(false);
      }
    },
  }),
});
