import React, { FC, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import classNames from 'classnames';
import { ClipLoader } from 'react-spinners';
import theme from 'theme';
import ASSETS from 'assets';
import { convertBytesToKbAndMb } from 'utils';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';

dayjs.extend(utc);

type Type = 'txt' | 'file' | 'img';

interface IProps {
  message: string;
  isMine: boolean;
  time: any;
  type: Type;
  msg: any;
}

const Image: FC<{ [x: string]: any }> = ({ msg, isMine, setImageUrl }) => {
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState<string>('');

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setUrl(msg.url);
    setImageUrl(msg.url);
    setLoading(true);
  };

  useEffect(() => {
    if (msg?.url) {
      if (isMine) {
        setUrl(msg.url);
        setImageUrl(msg.url);
      }
    }
    // eslint-disable-next-line
  }, [msg]);
  return (
    <div className='w-[300px] min-h-[300px] relative rounded-md overflow-hidden'>
      <img
        src={msg?.thumb}
        onLoad={() => {
          setLoading(false);
        }}
        className=' mb-2 w-[300px] object-contain rounded-md'
        alt=''
      />
      <div className='absolute w-full h-full top-0 left-0 flex items-center justify-center rounded-md overflow-hidden'>
        {url && (
          <img
            src={url}
            onLoad={() => {
              setLoading(false);
            }}
            className='rounded-md'
            alt=''
          />
        )}
      </div>
      {(loading || !url) && (
        <>
          {loading ? (
            <div className='absolute z-20 w-full h-full top-0 left-0 flex items-center justify-center  backdrop-blur-md rounded-md overflow-hidden'>
              <ClipLoader size={30} color={theme.colors.primary} />
            </div>
          ) : (
            <div
              className='absolute z-20 w-full h-full top-0 left-0 flex items-center justify-center  backdrop-blur-md rounded-md overflow-hidden'
              onClick={handleClick}
            >
              {!isMine && (
                <img
                  src={ASSETS.download}
                  className='w-10 drop-shadow-2xl'
                  alt=''
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const FileMessage: FC<{ [x: string]: any }> = ({
  msg,
  isMine,
  file,
  setFile,
}) => {
  const [loading, setLoading] = useState(true);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (file) {
      return;
    } else {
      handleFetchFile();
    }
  };

  const handleFetchFile = async () => {
    try {
      setLoading(true);
      const response = await fetch(msg.url);
      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }
      const blob = await response.blob();
      setFile(blob);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (msg?.url) {
      setLoading(false);
      if (isMine) {
        handleFetchFile();
      }
    }
    // eslint-disable-next-line
  }, [msg]);
  return (
    <div
      className='flex items-center justify-between gap-2 pb-2 '
      onClick={handleClick}
    >
      <img src={ASSETS.file} className='w-5' alt='' />
      <p className='text-sm font-medium truncate max-w-[40ch]'>
        {msg?.filename}
      </p>
      {loading ? (
        <>{!isMine && <ClipLoader size={20} color={theme.colors.primary} />}</>
      ) : (
        <>
          {file ? (
            <img src={ASSETS.checkCircle} className='w-4' alt='' />
          ) : (
            <img src={ASSETS.download} className='w-4' alt='' />
          )}
        </>
      )}
    </div>
  );
};

const Message: FC<IProps> = ({ message, isMine, time, type, msg }) => {
  const dispatch = useDispatch<Dispatch>();
  const [file, setFile] = useState<Blob | null>(null);
  const [imgUrl, setImageUrl] = useState('');
  const handleClick = () => {
    if (type === 'file') {
      if (file) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);
        link.download = msg.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    if (type === 'img') {
      if (imgUrl) {
        dispatch.utils.setOpenImagePreview(imgUrl);
      }
    }
  };
  return (
    <div
      className={classNames(
        'w-full flex justify-start',
        isMine && 'justify-end'
      )}
    >
      <div
        className={classNames(
          'max-w-[70%] min-w-[80px] p-3 bg-[#00808040] rounded-[12px] relative pb-5',
          isMine && 'bg-[#FBBC0540]'
        )}
        onClick={handleClick}
      >
        {type === 'txt' && <p className='text-sm'>{message}</p>}
        {type === 'img' && (
          <Image msg={msg} isMine={isMine} setImageUrl={setImageUrl} />
        )}
        {type === 'file' && (
          <FileMessage
            msg={msg}
            isMine={isMine}
            file={file}
            setFile={setFile}
          />
        )}
        {type === 'file' && (
          <p className='text-[10px] absolute font-medium text-end left-2 bottom-1'>
            {convertBytesToKbAndMb(msg?.file_length)}
          </p>
        )}
        <p className='text-[10px] absolute font-medium text-end right-2 bottom-1'>
          {dayjs.utc(time).local().format('hh:mm a')}
        </p>
      </div>
    </div>
  );
};

export default Message;
