import React, { FC, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import ASSETS from 'assets';
import { IBlockedUser } from 'types/api';
import { ClipLoader } from 'react-spinners';
import theme from 'theme';

interface IProps {
  user: IBlockedUser;
}

const ReportedUser: FC<IProps> = ({ user }) => {
  return (
    <div className='flex items-center gap-5'>
      <img
        src={user.image_url || ASSETS.preview}
        className='2xl:size-[50px] size-[45px] object-cover rounded-full'
        alt=''
      />
      <p className='text-lg font-medium'>
        {user.first_name} {user.last_name}
      </p>
    </div>
  );
};

const ReportedUsers = () => {
  const dispatch = useDispatch<Dispatch>();

  const { loading, reportedUser } = useSelector(
    (state: RootState) => state.reports
  );

  useEffect(() => {
    dispatch.reports.handleGetReportedUsers();
    // eslint-disable-next-line
  }, []);
  return (
    <div className='flex-grow 2xl:mt-11 mt-6 h-0 overflow-auto flex flex-col gap-4'>
      {reportedUser.length === 0 && loading && (
        <div className='py-10 flex items-center justify-center'>
          <ClipLoader size={50} color={theme.colors.primary} />
        </div>
      )}
      {reportedUser.map(user => (
        <ReportedUser key={user.id} user={user} />
      ))}
    </div>
  );
};

export default ReportedUsers;
