import React, { FC, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { object, string } from 'yup';

import Button from 'components/Button/Button';
import ImagePicker from 'components/ImagePicker/ImagePicker';
import TextField from 'components/TextField/TextField';

import DialogLayout from 'Dialogs/DialogLayout';
import ASSETS from 'assets';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { t } from 'i18next';
import LocationPicker from 'components/LocationPicker/LocationPicker';

const schema = object({
  name: string().required('Required field').label('Company name'),
  location: string().required('Required field').label('Location'),
});

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditCompany: FC<IProps> = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch<Dispatch>();

  const { user } = useSelector((state: RootState) => state.auth);
  const { loading } = useSelector((state: RootState) => state.companies);

  const [image, setImage] = useState<File | string | null>(null);

  const formik = useFormik({
    initialValues: {
      name: '',
      location: '',
      description: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      const formData = new FormData();
      if (typeof image !== 'string' && image) {
        formData.append('logo', image);
        dispatch.companies.handleUpdateCompany({
          values,
          formData,
          setOpen,
          id: user?.company[0].id,
        });
      } else {
        dispatch.companies.handleUpdateCompany({
          values,
          setOpen,
          id: user?.company[0].id,
        });
      }
    },
  });

  const onLocationChange = (val: string) => {
    formik.setFieldValue('location', val);
  };

  useEffect(() => {
    if (user?.company.length) {
      const company = user.company[0];
      formik.setValues({
        name: company.name,
        location: company.location,
        description: company.description,
      });
      setImage(company.company_logo);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <DialogLayout open={true}>
      <div className='w-full 2xl:max-w-[546px] max-w-[500px] bg-grey-100 rounded-[15px] 2xl:p-6 p-5'>
        <div className='flex justify-end'>
          <img
            src={ASSETS.cross}
            className='cursor-pointer'
            onClick={handleClose}
            alt=''
          />
        </div>
        <div className='2xl:w-[180px] w-[140px] mx-auto'>
          <ImagePicker image={image} setImage={setImage} />
        </div>
        <form
          onSubmit={e => e.preventDefault()}
          className='flex flex-col 2xl:gap-6 gap-4 w-full 2xl:mt-[38px] mt-7'
        >
          <TextField
            label=''
            placeholder={t('Company Name*')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : ''
            }
            name='name'
          />

          <LocationPicker
            name='location'
            placeholder={t('Location*')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.location}
            error={
              formik.touched.location && formik.errors.location
                ? formik.errors.location
                : ''
            }
            onValueChange={onLocationChange}
          />
          <Button
            label={t('Save Changes')}
            className='!w-full 2xl:!h-[56px] 2xl:!text-22 !text-lg 2xl:mt-[30px] mt-5'
            type='submit'
            onClick={() => formik.handleSubmit()}
            loading={loading}
          />
        </form>
      </div>
    </DialogLayout>
  );
};

export default EditCompany;
