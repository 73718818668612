import ASSETS from 'assets';
import classNames from 'classnames';
import { getTags } from 'http/jobService';
import { t } from 'i18next';
import React, { useState, useRef, useEffect, useCallback, FC } from 'react';
import { ITag } from 'types/interfaces';
import debounce from 'utils';

interface IProps {
  selected: string[];
  onChange: (v: string) => void;
  error?: string;
}

const JobTagsSelector: FC<IProps> = ({ selected, onChange, error }) => {
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState<ITag[]>([]);
  const [addedTags, setAddedTags] = useState<ITag[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const toggleMenu = () => setOpen(!open);

  const handleSelectTag = (val: string) => {
    if (typeof onChange === 'function') {
      setAddedTags([...addedTags]);
      onChange(val);
      setSearch('');
    }
  };

  const fetchTags = async (search: string) => {
    try {
      const { data } = await getTags(search);
      setTags(data.data.tags);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const handleAddTag = () => {
    const t: ITag = {
      id: Date.now().toString(),
      name: search,
      created_at: '',
      updated_at: '',
    };
    setAddedTags([...addedTags, t]);
    onChange(t.name);
    setSearch('');
  };

  // eslint-disable-next-line
  const debounceFetchTags = useCallback(
    debounce((searchTerm: string) => fetchTags(searchTerm), 300),
    []
  );

  const handleFetchTags = (search: string) => {
    debounceFetchTags(search);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleFetchTags(search);
    // eslint-disable-next-line
  }, [search]);

  return (
    <div className='w-full max-w-[800px]' ref={containerRef}>
      {/* <p className='text-base font-medium mb-1'>Job Tags Selector</p> */}
      <div className='w-full relative'>
        <div
          className={classNames(
            'flex items-center h-11 border rounded-lg border-grey-400 px-[15px] gap-2',
            error && 'border-red'
          )}
          onClick={toggleMenu}
        >
          <input
            type='text'
            value={search}
            onChange={handleChange}
            className='flex-grow h-full outline-none border-none rounded-lg placeholder:opacity-85 placeholder:text-black'
            placeholder={t('Tags*')}
          />
          {search && (
            <img
              src={ASSETS.check}
              onClick={handleAddTag}
              className='z-10 cursor-pointer'
              alt=''
            />
          )}
          <img src={ASSETS.chevronDown} alt='' />
        </div>
        {open && (
          <div className='border-[0.5px] flex-grow border-grey-400 bg-white rounded mt-[5px] shadow-mm max-h-[313px] overflow-auto empty:hidden z-10 absolute bottom-0 w-full translate-y-[calc(100%_+_8px)] '>
            {tags.map(tag => (
              <div
                key={tag.id}
                className='flex cursor-pointer items-center gap-[13px] py-[10px] px-4 border-b-[0.5px] border-b-grey-400 last-of-type:border-b-0 '
                onClick={() => {
                  handleSelectTag(tag.name);
                }}
              >
                <img
                  src={
                    selected.find(s => s === tag.name)
                      ? ASSETS.checkboxCheck
                      : ASSETS.checkbox
                  }
                  alt=''
                />
                <p className='text-sm font-medium'>{tag.name}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className='flex items-center gap-1 flex-wrap mt-2'>
        {selected.map(i => (
          <div
            key={i}
            className='bg-secondary px-[7px] h-7 flex items-center rounded'
          >
            <p className='text-[12px] font-medium'>{i}</p>
            <img
              src={ASSETS.cross}
              className='w-3 h-3 cursor-pointer'
              onClick={() => handleSelectTag(i)}
              alt=''
            />
          </div>
        ))}
        {error && (
          <div className='flex items-center gap-1 mt-1'>
            <img src={ASSETS.errorInfo} alt='' />
            <p className='text-xs text-error'>{t(error)}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobTagsSelector;
