import React, { useState } from 'react';

import classNames from 'classnames';
import GoPremium from 'Dialogs/GoPremium/GoPremium';

import Button from 'components/Button/Button';
import ASSETS from 'assets';
import { t } from 'i18next';

const JobApplications = () => {
  const [tab, setTab] = useState<'applied' | 'draft'>('applied');

  const [premium, setPremium] = useState(false);

  const handleTab = (t: typeof tab) => {
    setTab(t);
  };

  const handleDraft = () => {
    setPremium(true);
  };

  return (
    <div className='bg-white border border-grey-600 flex flex-col items-center rounded-[12px] 2xl:p-10 p-5  mt-5'>
      <p className='2xl:text-2xl text-xl font-medium text-center'>
        {t('Job Applications')}
      </p>
      <div className='tabs flex items-center 2xl:mt-8 mt-4 gap-3'>
        <Button
          label={t('Applied')}
          className={classNames(
            '2xl:!h-11 !h-9 2xl:!text-xl !text-base',
            tab !== 'applied' &&
              '!border !border-grey-400 !bg-grey-100 disabled:!bg-primaryDisabled disabled:!text-grey-600 !text-black'
          )}
          onClick={() => handleTab('applied')}
        />
        <Button
          onClick={handleDraft}
          label={t('Draft')}
          className={classNames(
            '2xl:!h-11 !h-9 2xl:!text-xl !text-base',
            tab !== 'draft' &&
              '!border !border-grey-400 !bg-primaryDisabled disabled:!text-grey-600 !text-black'
          )}
        />
      </div>
      {true ? (
        <div className='flex flex-col items-center 2xl:mt-11 mt-8'>
          <img src={ASSETS.noApplications} className='2xl:w-auto w-16' alt='' />
          <p className='2xl:text-2xl text-lg text-primary font-medium text-center mt-3'>
            {t('No Job Application yet')}
          </p>
          <p className='2xl:text-22 text-xl'>
            {t('You need premium to acess them')}
          </p>
        </div>
      ) : (
        <div className='flex flex-col w-full 2xl:mt-[37px] mt-5 gap-2'>
          <div className='flex items-center gap-[29px] rounded-lg shadow-mm h-[96px] px-3 overflow-auto bg-white'>
            <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
              <img src={ASSETS.job} alt='' />
            </div>
            <div>
              <div className='flex items-center gap-2'>
                <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                  Wardiere Software
                </p>
              </div>
              <p className='2xl:text-lg text-base font-medium leading-6'>
                UX UI Designer
              </p>
              <p className='2xl:text-base text-sm'>Florida, United States</p>
            </div>
          </div>
          <div className='flex items-center gap-[29px] rounded-lg shadow-mm h-[96px] px-3 overflow-auto bg-white'>
            <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
              <img src={ASSETS.job} alt='' />
            </div>
            <div>
              <div className='flex items-center gap-2'>
                <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                  Wardiere Software
                </p>
              </div>
              <p className='2xl:text-lg text-base font-medium leading-6'>
                UX UI Designer
              </p>
              <p className='2xl:text-base text-sm'>Florida, United States</p>
            </div>
          </div>
        </div>
      )}
      {premium && (
        <GoPremium
          open={premium}
          setOpen={setPremium}
          title={t('Want to view your saved drafts?')}
          message={t(
            'Premium access will allow you to access saved drafts and many other features!'
          )}
        />
      )}
    </div>
  );
};

export default JobApplications;
