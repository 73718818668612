import i18n from 'i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(localeData);
export const renderSeconds = (seconds: number) => {
  const mints = Math.floor(seconds / 60);
  const sec = seconds - mints * 60;
  return `${mints < 10 ? `0${mints}` : mints}:${sec < 10 ? `0${sec}` : sec}`;
};

export const convertTo12HourFormat = (hour24: number) => {
  const period = hour24 >= 12 ? 'pm' : 'am';
  const hour12 = hour24 % 12 || 12; // Convert 0 to 12
  return { hour: hour12, period };
};

export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  let timeout: NodeJS.Timeout | null = null;

  return (...args: Parameters<T>): void => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      func(...args);
      timeout = null;
    }, delay);
  };
};

export default debounce;

export const convertNumberToK = (n: number) => {
  if (n < 1000) return n;

  const result = n / 1000;
  return result % 1 === 0 ? `${result}k` : `${result.toFixed(1)}k`;
};

export const getLanguage = () => {
  return (
    i18n.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    'en'
  );
};

export const convertBytesToKbAndMb = (bytes: number) => {
  const kb = bytes / 1024;
  if (kb < 1024) {
    return `${kb.toFixed(1)} KB`;
  }
  const mb = kb / 1024;
  return `${mb.toFixed(1)} MB`;
};

export const formatTimestamp = (utcDate: string) => {
  const localDate = dayjs.utc(utcDate).local(); // Convert UTC to local time
  const now = dayjs();

  const isToday = now.isSame(localDate, 'day');
  const isYesterday = now.subtract(1, 'day').isSame(localDate, 'day');

  if (isToday) {
    return `Today at ${localDate.format('h:mm A')}`;
  } else if (isYesterday) {
    return `Yesterday at ${localDate.format('h:mm A')}`;
  } else if (now.diff(localDate, 'day') <= 7) {
    return `Last ${localDate.format('dddd')} at ${localDate.format('h:mm A')}`;
  } else {
    return `Last ${localDate.format('dddd')}`;
  }
};
