import React, { useState } from 'react';
import classNames from 'classnames';
import Button from 'components/Button/Button';
import ASSETS from 'assets';
import Unpost from 'Dialogs/Unpost/Unpost';
import UnpostJobReason from 'Dialogs/UnpostJobReason/UnpostJobReason';
import GoPremium from 'Dialogs/GoPremium/GoPremium';
import { t } from 'i18next';

const OpenJobs = () => {
  const [tab, setTab] = useState<'open' | 'closed' | 'draft'>('open');
  const [unpost, setUnpost] = useState(false);
  const [unpostJobReason, setUnpostJobReason] = useState(false);
  const [premium, setPremium] = useState(false);

  const handleOpenPremimum = () => setPremium(true);

  const onUnpost = () => setUnpostJobReason(true);

  const handleEditPost = () => setUnpost(true);

  const handleTab = (t: typeof tab) => {
    setTab(t);
  };
  return (
    <div className='bg-white border border-grey-600 rounded-[12px] 2xl:p-10 p-5 mt-5'>
      <p className='2xl:text-2xl text-xl font-medium text-center'>
        {t('Open Jobs')}
      </p>
      <div className='tabs flex items-center justify-center 2xl:mt-[50px] mt-4 gap-3'>
        <Button
          label={t('Open')}
          className={classNames(
            '2xl:!h-11 !h-9 2xl:!text-xl !text-base',
            tab !== 'open' &&
              '!border !border-grey-400 !bg-grey-100 disabled:!bg-primaryDisabled disabled:!text-grey-600 !text-black'
          )}
          onClick={() => handleTab('open')}
        />
        <Button
          label={t('Closed')}
          className={classNames(
            '2xl:!h-11 !h-9 2xl:!text-xl !text-base',
            tab !== 'closed' &&
              '!border !border-grey-400 !bg-grey-100 disabled:!bg-primaryDisabled disabled:!text-grey-600 !text-black'
          )}
          onClick={() => handleTab('closed')}
        />
        <Button
          onClick={handleOpenPremimum}
          label={t('Draft')}
          className={classNames(
            '2xl:!h-11 !h-9 2xl:!text-xl !text-base',
            tab !== 'draft' &&
              '!border !border-grey-400 !bg-grey-100 !bg-primaryDisabled !text-grey-600 '
          )}
        />
      </div>
      {tab === 'open' && (
        <div className='flex flex-col 2xl:mt-[37px] mt-5 gap-2'>
          <div className='flex items-center gap-[29px] rounded-lg shadow-mm h-[96px] px-3 overflow-auto bg-white'>
            <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
              <img src={ASSETS.job} alt='' />
            </div>
            <div>
              <div className='flex items-center gap-2'>
                <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                  Wardiere Software
                </p>
                <img
                  src={ASSETS.editBlack}
                  onClick={handleEditPost}
                  className='cursor-pointer'
                  alt=''
                />
              </div>
              <p className='2xl:text-lg text-base font-medium leading-6'>
                UX UI Designer
              </p>
              <p className='2xl:text-base text-sm'>Florida, United States</p>
            </div>
          </div>
          <div className='flex items-center gap-[29px] rounded-lg shadow-mm h-[96px] px-3 overflow-auto bg-white'>
            <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
              <img src={ASSETS.job} alt='' />
            </div>
            <div>
              <div className='flex items-center gap-2'>
                <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                  Wardiere Software
                </p>
                <img
                  src={ASSETS.editBlack}
                  onClick={handleEditPost}
                  className='cursor-pointer'
                  alt=''
                />
              </div>
              <p className='2xl:text-lg text-base font-medium leading-6'>
                UX UI Designer
              </p>
              <p className='2xl:text-base text-sm'>Florida, United States</p>
            </div>
          </div>
        </div>
      )}
      {tab === 'closed' && (
        <div className='flex flex-col 2xl:mt-[37px] mt-5 gap-2'>
          <div className='flex items-center gap-[29px] rounded-lg shadow-mm h-[96px] px-3 overflow-auto bg-white'>
            <div className='img  2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
              <img src={ASSETS.job} alt='' />
            </div>
            <div className='flex-grow  pr-[29px]'>
              <div className='flex flex-grow items-center  justify-between'>
                <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                  Wardiere Software
                </p>
                <p className='text-xs font-medium bg-[#D9D9D9] px-[10px] py-[6px] rounded-2xl'>
                  Unposted
                </p>
              </div>
              <p className='2xl:text-lg text-base font-medium leading-6'>
                UX UI Designer
              </p>
              <p className='2xl:text-base text-sm'>Florida, United States</p>
            </div>
          </div>
          <div className='flex items-center gap-[29px] rounded-lg shadow-mm h-[96px] px-3 overflow-auto bg-white'>
            <div className='img  2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
              <img src={ASSETS.job} alt='' />
            </div>
            <div className='flex-grow  pr-[29px]'>
              <div className='flex flex-grow items-center  justify-between'>
                <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                  Wardiere Software
                </p>
                <p className='text-xs font-medium bg-[#D9D9D9] px-[10px] py-[6px] rounded-2xl'>
                  Unposted
                </p>
              </div>
              <p className='2xl:text-lg text-base font-medium leading-6'>
                UX UI Designer
              </p>
              <p className='2xl:text-base text-sm'>Florida, United States</p>
            </div>
          </div>
        </div>
      )}
      {unpost && (
        <Unpost open={unpost} setOpen={setUnpost} onUnpost={onUnpost} />
      )}
      {unpostJobReason && (
        <UnpostJobReason open={unpostJobReason} setOpen={setUnpostJobReason} />
      )}
      {premium && (
        <GoPremium
          open={premium}
          setOpen={setPremium}
          title={t('Want to view your saved drafts?')}
          message={t(
            'Premium access will allow you to access saved drafts and many other features!'
          )}
        />
      )}
    </div>
  );
};

export default OpenJobs;
