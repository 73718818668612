import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { ROLE } from 'types/types';
import {
  IForgotPassword,
  ILoginPayload,
  IPhoneSignUp,
  IPhoneVerification,
  IRecoverEmail,
  IResetPasswordPayload,
  ISignUpPayload,
  IVerifyForgorPassword,
} from 'types/modals';
import {
  chatAuth,
  forgorPassword,
  forgotEmail,
  getChatToken,
  getCurrentUser,
  getEmailFromToken,
  login,
  onBoarding,
  phoneVerification,
  resendCode,
  resetPassword,
  updateInfo,
  updateMobile,
  verifyEmail,
  verifyForgotPasswordOtp,
} from 'http/authService';
import { IOnboading, IResendCode } from 'types/api';
import { IUser } from 'types/interfaces';
import { CONSTANTS } from 'app.config';
import axios from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { toast } from 'react-toastify';

interface IState {
  role: ROLE;
  roleCategory: string;
  authType: 'P' | 'E';
  loading: boolean;
  token: string;
  accessToken: string;
  user: IUser | null;
  phone: string;
  email: string;
  code: string;
  isLoggedIn: boolean;
  agoraToken: string;
}

export const auth = createModel<RootModel>()({
  name: 'auth',
  state: {
    role: 'applicant',
    roleCategory: '',
    authType: 'E',
    loading: false,
    user: null,
    token: '',
    accessToken: '',
    phone: '',
    email: '',
    code: '',
    isLoggedIn: !!localStorage.getItem(CONSTANTS.TOKEN),
    agoraToken: '',
  } as IState,
  reducers: {
    setRole(state, payload: ROLE) {
      state.role = payload;
    },
    setRoleCategroy(state, payload: string) {
      state.roleCategory = payload;
    },
    setAuthType(state, payload: 'P' | 'E') {
      state.authType = payload;
    },
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setToken(state, payload: string) {
      state.token = payload;
    },
    setUser(state, payload: IUser | null) {
      state.user = payload;
    },
    setAccessToken(state, payload: string) {
      state.accessToken = payload;
    },
    setPhone(state, payload: string) {
      state.phone = payload;
    },
    setEmail(state, payload: string) {
      state.email = payload;
    },
    setCode(state, payload: string) {
      state.code = payload;
    },
    setIsLoggedIn(state, payload: boolean) {
      state.isLoggedIn = payload;
    },
    setAgoraToken(state, payload: string) {
      state.agoraToken = payload;
    },
  },
  effects: dispatch => ({
    async HandlePhoneSignUp(payload: IPhoneSignUp) {
      const { values } = payload;
      try {
        dispatch.auth.setLoading(true);
        const { data } = await onBoarding(values);
        dispatch.auth.setToken(data.data.token);
        if (values.mobile) {
          dispatch.auth.setPhone(values.mobile);
          toast.success(`OTP code sent to ${values.mobile} successfully!`);
        }
        dispatch.utils.setemailVerification({
          open: true,
          type: 'phone',
        });
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async HandlePhoneVerification(payload: IPhoneVerification, state) {
      try {
        const token = state.auth.token;
        dispatch.auth.setLoading(true);
        const { data } = await phoneVerification({
          token,
          code: payload.values.code,
        });
        const { accessToken, refreshToken, ...user } = data.data;
        dispatch.auth.setAccessToken(accessToken);
        dispatch.auth.setUser(user);
        dispatch.utils.setemailVerification({ open: false, type: 'email' });
        if (user.step === 3) {
          localStorage.setItem(CONSTANTS.TOKEN, accessToken);
          payload.navigate('/explore');
          dispatch.auth.setIsLoggedIn(true);
        }
        dispatch.utils.setEmailVerified({
          open: true,
          type: 'phone',
        });
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          const msg = err.response?.data.message;
          payload.setError(msg);
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async HandleUpdateInfo(payload: ISignUpPayload, state) {
      try {
        const { values, navigate } = payload;
        const accessToken = state.auth.accessToken;
        dispatch.auth.setLoading(true);
        const { data } = await updateInfo(values, accessToken);
        dispatch.auth.setUser(data.data);
        localStorage.setItem(CONSTANTS.TOKEN, accessToken);
        dispatch.auth.setIsLoggedIn(true);
        navigate('/explore');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async HandleLogin(payload: ILoginPayload) {
      const { email, password, navigate, formik } = payload;
      try {
        dispatch.auth.setLoading(true);
        const { data } = await login({ email, password });
        if (data.data.signup_type === 'P' && !data.data.is_email_verified) {
          const { email_confirmation_token } = data.data;
          if (email_confirmation_token) {
            dispatch.auth.setToken(email_confirmation_token);
          }
          dispatch.utils.setemailVerification({
            open: true,
            type: 'email',
          });
        } else if (data.data.signup_type === 'E' && data.data.step === 1) {
          const { email_confirmation_token } = data.data;
          if (email_confirmation_token) {
            dispatch.auth.setToken(email_confirmation_token);
          }
          dispatch.auth.setAuthType('E');
          dispatch.auth.setEmail(data.data.email);
          dispatch.utils.setemailVerification({
            open: true,
            type: 'email',
          });
        } else {
          const { accessToken, refreshToken, ...user } = data.data;
          dispatch.auth.setAccessToken(accessToken);
          dispatch.auth.setUser(user);
          if (data.data.signup_type === 'E' && data.data.step === 2) {
            return navigate('/sign-up');
          }

          localStorage.setItem(CONSTANTS.TOKEN, accessToken);
          dispatch.auth.setIsLoggedIn(true);
          dispatch.auth.handleGetCurrentUser();
          navigate('/explore');
        }
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          const message = err.response?.data?.message;
          if (message === 'Password is incorrect') {
            formik.setErrors({ password: message });
          } else {
            formik.setErrors({ email: message });
          }
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleEmailVerification(
      payload: {
        code: string;
        setError: React.Dispatch<React.SetStateAction<string>>;
      },
      state
    ) {
      try {
        dispatch.auth.setLoading(true);
        const token = state.auth.token;
        const { data } = await verifyEmail({ token, code: payload.code });
        const { accessToken, refreshToken, ...user } = data.data;
        dispatch.auth.setAccessToken(accessToken);
        dispatch.auth.setUser(user);
        dispatch.utils.setEmailVerified({
          open: true,
          type: 'email',
        });
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          const msg = err.response?.data.message;
          payload.setError(msg);
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleSignUpWithEmail(payload: IOnboading) {
      try {
        dispatch.auth.setLoading(true);
        const { data } = await onBoarding(payload);
        const token = data.data.token;
        dispatch.auth.setToken(token);
        if (payload.email) {
          dispatch.auth.setEmail(payload.email);
        }
        toast.success(`OTP code sent to ${payload.email} successfully!`);
        dispatch.utils.setemailVerification({
          open: true,
          type: 'email',
        });
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleAddPhone(
      payload: { phone: string; navigate: NavigateFunction },
      state
    ) {
      try {
        const accessToken = state.auth.accessToken;
        dispatch.auth.setLoading(true);
        const { data } = await updateMobile(
          { mobile: payload.phone },
          accessToken
        );

        dispatch.auth.setUser(data.data);
        localStorage.setItem(CONSTANTS.TOKEN, accessToken);
        dispatch.auth.setIsLoggedIn(true);
        payload.navigate('/explore');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleResenCode(payload: IResendCode) {
      try {
        dispatch.auth.setLoading(true);
        const { data } = await resendCode(payload);
        dispatch.auth.setToken(data.data.token);
        toast.success(`OTP code sent to ${payload.email} successfully!`);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleForgotPassword(payload: IForgotPassword) {
      const { email, formik } = payload;
      try {
        dispatch.auth.setLoading(true);
        const { data } = await forgorPassword(email);
        dispatch.auth.setToken(data.data.token);
        dispatch.auth.setEmail(email);
        toast.success(`OTP code sent to ${email} successfully!`);
        dispatch.utils.setemailVerification({
          open: true,
          type: 'forgot-password',
        });
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          // eslint-disable-next-line
          const msg = err.response?.data.message;
          if (formik) {
            formik.setErrors({ email: 'Email does not exist.' });
          }
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleVerifyForgotPasswordOtp(payload: IVerifyForgorPassword, state) {
      const { code, navigate, setError } = payload;
      try {
        const token = state.auth.token;
        dispatch.auth.setLoading(true);
        await verifyForgotPasswordOtp(code, token);
        dispatch.utils.setemailVerification({ open: false, type: 'email' });
        dispatch.auth.setCode(code);
        navigate('/reset-password');
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          const msg = err.response?.data.message;
          setError(msg);
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleRecoverPassword(payload: IResetPasswordPayload, state) {
      const { values } = payload;

      try {
        const code = state.auth.code;
        const token = state.auth.token;
        dispatch.auth.setLoading(true);
        await resetPassword({ ...values, code }, token);
        toast.success('Password updated successfully!');
        dispatch.utils.setEmailVerified({
          open: true,
          type: 'forgot-password',
        });
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleForgotEmail(payload: {
      phone: string;
      setError: React.Dispatch<React.SetStateAction<string>>;
    }) {
      try {
        dispatch.auth.setLoading(true);
        const { data } = await forgotEmail({ mobile: payload.phone });
        dispatch.auth.setToken(data.data.phone_confirmation_token);
        dispatch.auth.setPhone(payload.phone);
        dispatch.utils.setemailVerification({
          open: true,
          type: 'forgot-email',
        });
        toast(`OTP code sent to ${payload.phone} successfully!`);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          payload.setError('Phone number does not exist');
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleRecoverEmail(payload: IRecoverEmail, state) {
      const { setError, code } = payload;
      try {
        const token = state.auth.token;
        dispatch.auth.setLoading(true);
        const { data } = await getEmailFromToken(code, token);
        dispatch.auth.setEmail(data.data.email);
        dispatch.utils.setemailVerification({ open: false, type: 'email' });
        dispatch.utils.setEmailVerified({
          open: true,
          type: 'forgot-email',
        });
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          const msg = err.response?.data.message;
          setError(msg);
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleGetCurrentUser() {
      try {
        dispatch.auth.setLoading(true);
        const { data } = await getCurrentUser();
        dispatch.auth.setUser(data.data);
        if (data.data.agora_user_name === null) {
          await chatAuth();
        }
        const { data: res } = await getChatToken();
        dispatch.auth.setAgoraToken(res.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
  }),
});
