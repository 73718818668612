import React, {
  FC,
  useEffect,
  useRef,
  useState,
  useContext,
  useId,
} from 'react';
import ASSETS from 'assets';
import ClickAwayListener from 'react-click-away-listener';
import Message from './Message';
// import ActionMessage from './ActionMessage';
import { AgoraContext } from 'context/AgoraContext';

import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { Link, useNavigate } from 'react-router-dom';
import AutomatedMessages from 'Dialogs/AutomatedMessages/AutomatedMessages';
import { t } from 'i18next';
import AC, { AgoraChat } from 'agora-chat';
import { applicantRoles } from 'app.config';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import theme from 'theme';
import ImagePreview from 'Dialogs/ImagePreview/ImagePreview';
import { getJobById } from 'http/jobService';
import { blockCompany } from 'http/companyService';
import { blockUser } from 'http/userService';

const Messages: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const conn = useContext(AgoraContext);

  const ref = useRef<HTMLTextAreaElement | null>(null);
  const scroller = useRef<HTMLDivElement>(null);

  const imagePicker = useId();
  const filePicker = useId();

  const [openMenu, setOpenMenu] = useState(false);
  const [value, setValue] = useState('');
  const [miniMenu, setMiniMenu] = useState(false);
  const [messages, setMessages] = useState<AgoraChat.MessagesType[] | any>([]);
  const [, setLoading] = useState(false);
  const [isApplicant, setIsApplicant] = useState(false);
  const [, setImage] = useState<File | null>(null);
  const [dLoading, setDLoading] = useState(false);

  const [openAutoMessages, setOpenAutoMessages] = useState(false);

  const { user } = useSelector((state: RootState) => state.auth);
  const { selected } = useSelector((state: RootState) => state.chat);
  const { imagePreview } = useSelector((state: RootState) => state.utils);
  const [companyId, setCompanyId] = useState('');
  const [bLoading, setBloading] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const closeMenu = () => {
    setOpenMenu(false);
  };

  const toggleMiniMenu = () => setMiniMenu(!miniMenu);
  const closeMiniMenu = () => setMiniMenu(false);

  const handleBlock = () => {
    dispatch.utils.openActionDialog({
      open: true,
      title: 'Block Profile',
      message: 'Are you sure you want to block this profile?',
      buttonTitle: 'Block',
      loading: bLoading,
      onClick: async () => {
        try {
          setBloading(true);
          if (isApplicant && companyId) {
            await blockCompany(companyId);
          } else {
            if (selected?.applicant_id) {
              await blockUser(selected?.applicant_id);
            }
          }
          toast.success('Blocked Successfully!');
          dispatch.utils.closeActionDialog();
          if (user) {
            dispatch.chat.handleFetchChats({ user, conn });
          }
        } catch (err: any) {
        } finally {
          setBloading(false);
        }
      },
    });
  };

  // const handleReport = () => {
  //   dispatch.utils.openActionDialog({
  //     open: true,
  //     title: 'Report User',
  //     message: 'Are you sure you want to report this user?',
  //     buttonTitle: 'Report',
  //     onClick: () => {
  //       navigate('/report');
  //       dispatch.utils.closeActionDialog();
  //     },
  //   });
  // };

  const handleSchedular = () => {
    navigate('/chat/schedule');
  };

  const handleSendMessage = async () => {
    try {
      if (value.trim() === '') {
        return null;
      }
      let v = value;
      setValue('');
      setLoading(true);
      const msg = AC.message.create({
        to: selected!.group_id,
        msg: v,
        chatType: 'groupChat',
        type: 'txt',
        // msgConfig: { allowGroupAck: true },
      });
      const newMessages = [
        ...messages,
        { ...msg, from: user?.agora_user_name } as AgoraChat.MessagesType,
      ];
      setMessages(newMessages);
      await conn.send(msg);
      if (selected) {
        const d = { ...selected };
        // @ts-ignore
        d.lastMsg = { ...msg, from: user?.agora_user_name };
        d.unreadCount = 0;
        dispatch.chat.updateChat(d);
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFileSuccess = (index: number, data: any) => {
    setMessages((prevMessages: any) => {
      const newMessages = [...prevMessages];
      if (newMessages[index]) {
        newMessages[index] = {
          ...newMessages[index],
          url: data.url,
        };
      } else {
        console.warn('Message not found at index', index);
      }

      return newMessages;
    });
  };

  const handleSendAutomatedMessage = async (m: string) => {
    try {
      setLoading(true);
      if (m.trim() === '') {
        return null;
      }
      setValue('');
      setLoading(true);
      const msg = AC.message.create({
        to: selected!.group_id,
        msg: m,
        chatType: 'groupChat',
        type: 'txt',
        // msgConfig: { allowGroupAck: true },
      });
      const newMessages = [
        ...messages,
        { ...msg, from: user?.agora_user_name } as AgoraChat.MessagesType,
      ];
      setMessages(newMessages);
      await conn.send(msg);
      if (selected) {
        const d = { ...selected };
        // @ts-ignore
        d.lastMsg = { ...msg, from: user?.agora_user_name };
        d.unreadCount = 0;
        dispatch.chat.updateChat(d);
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(true);
    }
  };

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      const selectedImage = e.currentTarget.files[0];
      setImage(selectedImage); // Set image state
      handleSendImageMessage(selectedImage); // Pass the image directly
    }
  };

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      const selectedFile = e.currentTarget.files[0];
      console.log('File', selectedFile);
      handleSendFileMessage(selectedFile); // Pass the file directly
      e.currentTarget.value = '';
    }
  };

  const handleSendFileMessage = async (selectedFile: File) => {
    try {
      if (!selectedFile) return;
      const fileObj = {
        url: '',
        filename: selectedFile.name,
        filetype: selectedFile.type,
        data: selectedFile,
      };

      console.log('File Object', fileObj);

      const index = messages.length;

      const msg = AC.message.create({
        chatType: 'groupChat',
        type: 'file',
        to: selected!.group_id,
        file: fileObj,
        onFileUploadProgress: data => {
          console.log('Progress', data);
        },
        onFileUploadComplete: data => {
          handleFileSuccess(index, data);
        },
        onFileUploadError: err => {
          console.error('File upload error:', err);
        },
      });
      const newMessage = {
        ...msg,
        from: user?.agora_user_name,
        file_length: selectedFile.size,
        filename: selectedFile.name,
        filetype: selectedFile.type,
        url: '',
      };
      setMessages([...messages, newMessage]);
      await conn.send(msg);
      if (selected) {
        const d = { ...selected };
        // @ts-ignore
        d.lastMsg = newMessage;
        d.unreadCount = 0;
        dispatch.chat.updateChat(d);
      }
    } catch (err: any) {
      console.log('errr', err.message);
    }
  };

  const handleSendImageMessage = async (selectedImage: File) => {
    if (!selectedImage) return;
    const fileObj = {
      url: '',
      filename: selectedImage.name,
      filetype: selectedImage.type,
      data: selectedImage,
    };
    const index = messages.length;

    const msg = AC.message.create({
      chatType: 'groupChat',
      type: 'img',
      to: selected!.group_id,
      file: fileObj,
      onFileUploadProgress: data => {
        console.log('Progress', data);
      },
      onFileUploadComplete: data => {
        handleFileSuccess(index, data);
      },
      onFileUploadError: err => {
        console.error('File upload error:', err);
      },
    });
    const newMessage = {
      ...msg,
      from: user?.agora_user_name,
      thumb: URL.createObjectURL(selectedImage),
      url: '',
    };

    setMessages([...messages, newMessage]);

    await conn.send(msg);
    if (selected) {
      const d = { ...selected };
      // @ts-ignore
      d.lastMsg = newMessage;
      d.unreadCount = 0;
      dispatch.chat.updateChat(d);
    }
  };

  const handleAddMessage = (data: any) => {
    setMessages((prev: any) => [...prev, data]);
  };

  const handleFetchMessages = async () => {
    let allMessages: any[] = [];
    let cursor: string = '';

    const fetchMessages = async () => {
      try {
        const data = await conn.getHistoryMessages({
          targetId: selected!.group_id,
          chatType: 'groupChat',
          pageSize: 50,
          cursor,
        });

        console.log(data);

        const filteredMessages = data.messages?.filter(
          m => m.type !== 'custom'
        );
        allMessages = [...allMessages, ...filteredMessages];

        if (data.cursor && !data.isLast) {
          cursor = data.cursor;
          await fetchMessages();
        } else {
          setMessages(allMessages);
        }
      } catch (err: any) {
        console.log('Error fetching messages:', err.message);
      }
    };

    await fetchMessages();
    setMessages(allMessages.reverse());
    console.log(allMessages.length);
  };

  const handleDeleteChat = async () => {
    try {
      setDLoading(true);
      await conn.deleteConversation({
        channel: selected!.group_id,
        chatType: 'groupChat',
        deleteRoam: true,
      });
      setMessages([]);
      if (user) {
        dispatch.chat.handleFetchChats({ user, conn });
      }
      toast.success('Chat deleted successfully!');
    } catch (err: any) {
      toast.error('Something went wrong!');
    } finally {
      setDLoading(false);
    }
  };

  // const handleFetchMessages = () => {
  //   conn
  //     .getHistoryMessages({
  //       targetId: selected!.group_id,
  //       chatType: 'groupChat',
  //       pageSize: 50,
  //     })
  //     .then(data => {
  //       console.log(data.messages);
  //       setMessages([
  //         ...data.messages?.reverse().filter(m => m.type !== 'custom'),
  //       ]);
  //     })
  //     .catch(err => console.log('err', err.message));
  // };

  const handleGetCompanyId = async () => {
    try {
      if (isApplicant && selected?.job_id) {
        const { data } = await getJobById(selected?.job_id);
        setCompanyId(data.data.company_details.id);
      }
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = '0px';
      const scroll = ref.current.scrollHeight;
      ref.current.style.height = `${scroll}px`;
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (!selected && messages.length) {
      setMessages([]);
    }
    if (selected) {
      handleFetchMessages();
    }
    conn.addEventHandler('message', {
      onTextMessage: data => {
        if (selected) {
          if (data.to === selected.group_id) {
            handleAddMessage(data);
            try {
              // const msg = AC.message.create({
              //   type: 'read',
              //   chatType: 'groupChat',
              //   id: data.id,
              //   to: data.to,
              //   ackContent: JSON.stringify({}),
              // });
              // conn.send(msg);
            } catch (err: any) {
              console.log('Error in acknowledge');
              console.log(err.message);
            }
          }
        }
      },
      onFileMessage: data => {
        if (selected) {
          if (data.to === selected.group_id) {
            handleAddMessage(data);
          }
        }
      },
      onImageMessage: data => {
        if (selected) {
          if (data.to === selected.group_id) {
            handleAddMessage(data);
          }
        }
      },
    });
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    if (selected && isApplicant) {
      handleGetCompanyId();
    }
    // eslint-disable-next-line
  }, [selected, isApplicant]);

  useEffect(() => {
    if (scroller.current) {
      scroller.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line
  }, [messages]);

  useEffect(() => {
    if (user) {
      if (applicantRoles.includes(user.role || '')) {
        setIsApplicant(true);
      } else {
        setIsApplicant(false);
      }
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <div className='flex-grow bg-white flex flex-col'>
      <div className='2xl:h-[80px] h-[65px] border-b-[0.5px] border-b-grey-600 flex items-center justify-between px-6'>
        {selected && (
          <>
            <Link
              to={
                isApplicant
                  ? `/other-employer/${companyId}`
                  : `/other-job-seeker/${selected?.applicant_id}`
              }
            >
              <div className='flex items-center 2xl:gap-6 gap-4'>
                <img
                  src={
                    isApplicant
                      ? selected?.company_logo || ASSETS.preview
                      : selected?.image_url || ASSETS.preview
                  }
                  className='2xl:size-[55px] size-[50px] rounded-full'
                  alt=''
                />
                <p className='2xl:text-xl text-lg font-medium'>
                  {isApplicant ? selected?.name : selected?.user_name}
                </p>
              </div>
            </Link>
            <div className='flex items-center gap-3'>
              {!isApplicant && (
                <div className='cursor-pointer' onClick={handleSchedular}>
                  <img
                    src={ASSETS.schedule}
                    className='w-5 2xl:w-auto'
                    alt=''
                  />
                </div>
              )}
              <div className='relative'>
                <img
                  src={ASSETS.menu}
                  className='cursor-pointer h-5 2xl:h-auto'
                  onClick={toggleMenu}
                  alt=''
                />
                {openMenu && (
                  <ClickAwayListener onClickAway={closeMenu}>
                    <div className='menu absolute z-10  w-[150px] shadow-popup rounded-[8px] bg-white right-0 flex flex-col gap-[2px] overflow-hidden 2xl:top-9 top-6'>
                      <div
                        className='px-4 flex items-center gap-[9px] py-2 hover:bg-grey-100 cursor-pointer'
                        onClick={() => {
                          handleBlock();
                          closeMenu();
                        }}
                      >
                        <img src={ASSETS.blockCircle} alt='' />
                        <p className='text-base font-medium'>{t('Block')} </p>
                      </div>
                      {/* <div
                    className='px-4 flex items-center gap-[9px] py-2 hover:bg-grey-100 cursor-pointer'
                    onClick={() => {
                      handleReport();
                      closeMenu();
                    }}
                  >
                    <img src={ASSETS.flag} alt='' />
                    <p className='text-base font-medium'>{t('Report User')} </p>
                  </div> */}
                      <div
                        className='px-4 flex items-center gap-[9px] py-2 hover:bg-grey-100 cursor-pointer'
                        onClick={handleDeleteChat}
                      >
                        {dLoading ? (
                          <div className='w-full flex justify-center'>
                            <ClipLoader
                              size={20}
                              color={theme.colors.primary}
                            />
                          </div>
                        ) : (
                          <>
                            <img src={ASSETS.bin} alt='' />
                            <p className='text-base font-medium'>
                              {t('Delete Chat')}{' '}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </ClickAwayListener>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className='flex-grow flex flex-col py-5 overflow-auto h-0 2xl:px-[32px] px-5 gap-5'>
        {!selected && (
          <div className=' flex-grow flex items-center justify-center'>
            <p className='text-3xl  text-secondary'>No Chat Selected</p>
          </div>
        )}
        {messages.map((msg: any) => (
          <Message
            message={msg?.msg}
            isMine={msg.from === user?.agora_user_name ? true : false}
            time={msg?.time}
            type={msg?.type}
            msg={msg}
          />
        ))}

        {/* <Message
          message='Hi, I am doing well too, Yes, you can see my resume I applied through'
          isMine={true}
        />
        <Message
          message='Yes, sure. I can send you the weblink Hope that works for you? '
          isMine={false}
        />

        <Message
          message='Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae harum ipsa odio, quibusdam velit voluptate corrupti, sint sequi recusandae, rerum rem deleniti! Odit esse inventore quae quis, dignissimos illum perspiciatis.'
          isMine={true}
        /> */}
        {/* <ActionMessage /> */}
        <div ref={scroller} />
      </div>
      {/* Chat input */}
      {selected && (
        <div className='2xl:pb-[30px] 2xl:px-[32px] pb-5 px-5 pt-2'>
          <div className='w-full bg-white rounded-[28px] 2xl:min-h-[60px] min-h-[50px] shadow-popup flex items-center gap-[10px] px-[10px] py-[10px]'>
            <div className='relative'>
              <img
                src={ASSETS.addCircle}
                onClick={toggleMiniMenu}
                className='cursor-pointer'
                alt=''
              />
              {miniMenu && (
                <ClickAwayListener onClickAway={closeMiniMenu}>
                  <div
                    className='absolute w-[49px] py-5  top-0 translate-y-[calc(-100%_-_27px)] rounded-3xl flex flex-col gap-[11px] left-0 translate-x-[-20%] bg-grey-100 
            shadow-[0px_4px_4px_0px_#00000040]'
                  >
                    <label
                      htmlFor={imagePicker}
                      className='grid place-items-center cursor-pointer'
                    >
                      <img src={ASSETS.img} alt='' />
                    </label>
                    <label
                      htmlFor={filePicker}
                      className='grid place-items-center cursor-pointer'
                    >
                      <img src={ASSETS.file} alt='' />
                    </label>
                    {/* <div className='grid place-items-center cursor-pointer'>
                      <img src={ASSETS.gif} alt='' />
                    </div>
                    <div className='grid place-items-center cursor-pointer'>
                      <img src={ASSETS.emoji} alt='' />
                    </div> */}
                  </div>
                </ClickAwayListener>
              )}
            </div>
            <textarea
              name=''
              className='flex-grow resize-none outline-none 2xl:text-lg text-sm 2xl:h-7 h-6 max-h-[150px] 2xl:leading-[24px] leading-5'
              placeholder='Write a message....'
              id=''
              ref={ref}
              value={value}
              onChange={e => setValue(e.currentTarget.value)}
              onKeyDown={e => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
            ></textarea>
            {!isApplicant && (
              <div>
                <img
                  src={ASSETS.quickMessage}
                  onClick={() => setOpenAutoMessages(true)}
                  className='cursor-pointer'
                  alt=''
                />
              </div>
            )}
            <div
              className='2xl:size-10 size-9 rounded-full grid place-items-center bg-primary'
              onClick={handleSendMessage}
            >
              <img src={ASSETS.sendWhite} className='2xl:w-auto w-5' alt='' />
            </div>
          </div>
        </div>
      )}
      {openAutoMessages && (
        <AutomatedMessages
          open={openAutoMessages}
          setOpen={setOpenAutoMessages}
          onSend={handleSendAutomatedMessage}
        />
      )}
      <input
        type='file'
        accept='image/png,image/jpg,image/jpeg,image/bmp,image/webp,image/tiff,image/ico'
        className='hidden'
        id={imagePicker}
        onChange={handleImage}
      />
      <input
        type='file'
        name=''
        id={filePicker}
        onChange={handleFile}
        className='hidden'
        accept='.pdf, .doc, .docx, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      />
      {imagePreview.open && imagePreview.imgURL && <ImagePreview />}
    </div>
  );
};

export default Messages;
