import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import ClickAwayListener from 'react-click-away-listener';
import ASSETS from 'assets';
import { formatTimestamp } from 'utils';

dayjs.extend(relativeTime);

const Notification = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch<Dispatch>();

  const { read, unread, unreadCound } = useSelector(
    (state: RootState) => state.notifications
  );

  const toggleMenu = () => setOpen(!open);
  const closeMenu = () => setOpen(false);

  const handleNavigate = () => {
    navigate('/notifications');
    closeMenu();
  };

  useEffect(() => {
    if (!open) {
      dispatch.notifications.handleGetNotifications();
    } else {
      dispatch.notifications.handleMarkAllAsRead(null);
    }
  }, [open]);

  return (
    <Container>
      <div
        className='cursor-pointer relative'
        role='button'
        onClick={toggleMenu}
      >
        <img src={ASSETS.notification} className='w-9 2xl:w-[45px]' alt='' />
        {unreadCound > 0 && (
          <div className='absolute w-3 h-3 bg-red top-0 right-3 rounded-full'></div>
        )}
      </div>
      {open && (
        <ClickAwayListener onClickAway={closeMenu}>
          <div className='menu shadow-popup bg-white w-[100vw] max-w-[640px] absolute right-0 bottom-0 translate-y-[calc(100%_+_0px)] rounded py-3 z-10 flex flex-col'>
            <div className='header 2xl:px-[25px] px-4'>
              <p className='2xl:text-32 text-2xl font-medium'>
                {t('Notifications')}
              </p>
            </div>
            <div className='max-h-[50vh] overflow-auto'>
              {unread.length > 0 && (
                <div className='unread-messages 2xl:mt-[20px] mt-4'>
                  <p className='2xl:text-base text-sm font-medium text-placeholder 2xl:px-[31px] pb-1 px-6'>
                    {t('Unread')}
                  </p>
                  {unread.map(n => (
                    <div className='notification bg-grey-200 2xl:py-[18px] py-3 flex items-center gap-[11px] 2xl:px-[29px] px-5 w-full border-b border-b-grey-400'>
                      <div className='2xl:size-10 size-8 grid place-items-center rounded-full bg-primary'>
                        <img
                          src={ASSETS.bookmarkWhite}
                          className='w-3 2xl:w-auto'
                          alt=''
                        />
                      </div>
                      <div>
                        <p className='2xl:text-lg text-base font-medium'>
                          {n.message}
                        </p>
                        <p className='2xl:text-base text-sm font-medium text-placeholder'>
                          {/* {t('Today at')} 9:42 AM */}
                          {/* {dayjs.utc(n.created_at).local().fromNow()} */}
                          {formatTimestamp(n.created_at)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {read.length > 0 && (
                <div className='unread-messages'>
                  <p className='text-base font-medium text-placeholder px-[31px] pt-3'>
                    {t('Read')}
                  </p>
                  {read.map(n => (
                    <div className='notification  2xl:py-[18px] pb-3 pt-1 flex items-center gap-[11px] 2xl:px-[29px] px-5 w-full border-b border-b-grey-400'>
                      <div className='2xl:size-10 size-8 grid place-items-center rounded-full bg-primary'>
                        <img
                          src={ASSETS.bookmarkWhite}
                          className='w-3 2xl:w-auto'
                          alt=''
                        />
                      </div>
                      <div>
                        <p className='2xl:text-lg text-base font-medium'>
                          {n.message}
                        </p>
                        <p className='2xl:text-base text-sm font-medium text-placeholder'>
                          {/* {dayjs.utc(n.created_at).local().fromNow()} */}
                          {formatTimestamp(n.created_at)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <p
              className='2xl:text-base text-sm 2xl:px-[30px] px-5 pt-[10px] cursor-pointer'
              role='button'
              onClick={handleNavigate}
            >
              {t('See all notifications')}
            </p>
          </div>
        </ClickAwayListener>
      )}
    </Container>
  );
};

export default Notification;

const Container = styled.div`
  position: relative;
`;
