import React, { FC } from 'react';

import DialogLayout from 'Dialogs/DialogLayout';
import ASSETS from 'assets';
import Button from 'components/Button/Button';
import { t } from 'i18next';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onUnpost: () => void;
}

const Unpost: FC<IProps> = ({ open, setOpen, onUnpost }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleUnpost = () => {
    onUnpost();
    handleClose();
  };
  return (
    <DialogLayout open={open}>
      <div className='w-full 2xl:max-w-[576px] max-w-[440px] bg-grey-100 rounded-[16px] px-[21px] 2xl:pb-[49px] pb-8 2xl:pt-[30px] pt-6'>
        <div className='flex justify-end'>
          <img
            src={ASSETS.cross}
            onClick={handleClose}
            className='cursor-pointer'
            alt=''
          />
        </div>
        <div className='flex justify-center'>
          <img src={ASSETS.trash} alt='' />
        </div>
        <p className='2xl:text-32 text-2xl text-primary font-medium text-center 2xl:mt-8 mt-4'>
          {t('Are you sure you want to unpost?')}
        </p>
        <div className='flex flex-col gap-3 max-w-[339px] mx-auto 2xl:mt-[54px] mt-8'>
          <Button
            label={t('Unpost')}
            className='!text-lg'
            onClick={handleUnpost}
          />
          <Button
            label={t('Cancel')}
            variant='outlined'
            className='!text-lg'
            onClick={handleClose}
          />
        </div>
      </div>
    </DialogLayout>
  );
};

export default Unpost;
