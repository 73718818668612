import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import ASSETS from 'assets';
import { useEffect } from 'react';
import NotFound from './NotFound';
import { t } from 'i18next';
import Button from 'components/Button/Button';

const SavedJobs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { savedJobs, loading } = useSelector(
    (state: RootState) => state.myjobs
  );

  const handleClick = (id: string) => {
    navigate(`/job/${id}`);
  };

  useEffect(() => {
    dispatch.myjobs.handleGetSavedJobs();
  }, []);

  return (
    <Container className='2xl:pt-[33px] pt-5'>
      <JobsSection>
        {savedJobs.length === 0 && (
          <NotFound
            message={t("You don't have any saved jobs yet")}
            icon={ASSETS.saved}
            children={
              <Button
                label={t('Explore Jobs')}
                variant='outlined'
                onClick={() => navigate('/explore')}
              />
            }
          />
        )}
        {savedJobs.map((job, i) => (
          <Job
            key={i}
            className='rounded-lg 2xl:gap-[29px] gap-4 shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white cursor-pointer'
            onClick={() => handleClick(job.job_id)}
          >
            <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
              <img src={job.company_logo || ASSETS.preview} alt='' />
            </div>
            <div>
              <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                {job.company_name}
              </p>
              <p className='2xl:text-lg text-base font-medium leading-6'>
                {job.title}
              </p>
              <p className='2xl:text-base text-sm'>{job.company_location}</p>
            </div>
          </Job>
        ))}
      </JobsSection>
    </Container>
  );
};

export default SavedJobs;

const Container = styled.div`
  max-width: 820px;
  width: 100%;
  margin-inline: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const JobsSection = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Job = styled.div`
  display: flex;
  align-items: center;
`;
