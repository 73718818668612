import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import Accordion from './Accordion';
import ASSETS from 'assets';
import { t } from 'i18next';

const items = [
  'Architecture and Construction',
  'Arts, Design, Entertainment, Sports, and Media',
  'Building and Grounds Cleaning and Maintenance',
  'Business and Finance',
  'Community and Social Services',
  'Science, Technology, Engineering, and Mathematics',
  'Construction and Extraction',
  'Farming, Fishing and Forestry',
  'Food Preparation and Serving Related',
  'Education, Training, and Library',
  'Healthcare Practitioners and Technical',
  'Healthcare Support',
  'Installation, Maintenance and Repair',
  'Legal',
  'Life, Physical and Social Science',
  'Business Management and Administration',
];

const AreaOfExperties = () => {
  const dispatch = useDispatch<Dispatch>();
  const { areaOfExperties: selected } = useSelector(
    (state: RootState) => state.filters
  );

  const handleSelect = (op: string) => {
    dispatch.filters.setListFilters({ name: 'areaOfExperties', value: op });
  };
  return (
    <Accordion label='Area of Expertise'>
      <div className='px-2 pb-1'>
        <div className='flex flex-col rounded overflow-hidden'>
          {items.map(j => (
            <div
              className='flex items-center gap-1 cursor-pointer py-1 border-b-[0.5px] flex-grow overflow-auto px-1 border-grey-400 bg-white [&:last-of-type]:border-none'
              onClick={() => handleSelect(j)}
            >
              <img
                src={
                  selected.includes(j) ? ASSETS.checkboxCheck : ASSETS.checkbox
                }
                className='w-4'
                alt=''
              />
              <p className='text-sm'>{t(j)}</p>
            </div>
          ))}
        </div>
      </div>
    </Accordion>
  );
};

export default AreaOfExperties;
