import { IApiResponse, ICreateCompany } from 'types/api';
import api from './index';
import { ROLES } from 'types/types';
import { ICompany } from 'types/interfaces';

export const createCompany = (data: ICreateCompany) =>
  api.post<IApiResponse<ICompany>>('/v1/company', data);

export const getUserCompanyByRole = (role: ROLES) =>
  api.get<IApiResponse<ICompany[]>>(`/v1/company/user/${role}`);

export const getAllCompanies = () =>
  api.get<
    IApiResponse<{
      companies: ICompany[];
      totalPages: number;
      currentPage: number;
    }>
  >('/v1/company');

export const updateCompanyImage = (id: string, data: FormData) =>
  api.patch(`/v1/company/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const updateCompanyDetails = (data: ICreateCompany, id: string) =>
  api.post(`/v1/company?id=${id}`, data);

export const getCompanyById = (id: string) =>
  api.get<IApiResponse<ICompany>>(`/v1/company/${id}`);

export const getCompaniesByName = (name: string) =>
  api.get<
    IApiResponse<{
      companies: ICompany[];
      totalPages: number;
      currentPage: number;
    }>
  >(`/v1/company${name ? `?name=${name}` : ''}`);

export const blockCompany = (id: string) =>
  api.post('/v1/user/block', {
    isReport: false,
    company_id: id,
  });

export const saveCompanyProfile = (id: string) =>
  api.post('/v1/user/save', {
    company_id: id,
  });
