import React, { useState, useRef, useEffect } from 'react';

import { FFmpeg } from '@ffmpeg/ffmpeg';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { fetchFile } from '@ffmpeg/util';
import Draggable from 'react-draggable';
import { useNavigate } from 'react-router-dom';

import ASSETS from 'assets';
import Button from 'components/Button/Button';
import { t } from 'i18next';

const ffmpeg = new FFmpeg();

const VideoTrimmer = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const { selected, data } = useSelector((state: RootState) => state.video);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const frameVideoRef = useRef<HTMLVideoElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(true);
  const [videoSrc, setVideoSrc] = useState<string>('');
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [, setEndTime] = useState<number>(60);
  const [frames, setFrames] = useState<string[]>([]);
  const [dragWidth, setDragWidth] = useState(0);
  const [trimStartTime, setTrimStartTime] = useState(0);
  const [trimEndTime, setTrimEndTime] = useState(0);
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
  const [isPlaying, setIsPlaying] = useState(false);
  const [time, setTime] = useState('00:00');

  const handleDrag = (e: any, data: any) => {
    // const parentWidth = e.target.parentElement.clientWidth;
    // const newTime = (data.x / parentWidth) * videoDuration;
    // setTrimStartTime(newTime);
    // setTrimEndTime(newTime + +selected!.duration);
    // setDragPosition({ x: data.x, y: 0 });
    // if (videoRef.current && progressRef.current) {
    //   const duration = videoRef.current.duration;
    //   const left = (newTime / duration) * 100;
    //   progressRef.current.style.left = `${left}%`;
    // }
  };

  const handleDragStop = (e: any, data: any) => {
    const parentWidth = e.target.parentElement.clientWidth;
    const newTime = (data.x / parentWidth) * videoDuration;
    setTrimStartTime(newTime);
    setTrimEndTime(newTime + +selected!.duration);
    setDragPosition({ x: data.x, y: 0 });
    if (videoRef.current && progressRef.current) {
      const duration = videoRef.current.duration;
      const left = (newTime / duration) * 100;
      progressRef.current.style.left = `${left}%`;
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current && progressRef.current) {
      const duration = videoRef.current.duration;
      const videoTime = videoRef.current.currentTime;
      const left = (videoTime / duration) * 100;
      progressRef.current.style.left = `${left}%`;
      const time = Math.floor(videoTime);
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      setTime(
        `${minutes < 10 ? '0' : ''}${minutes}:${
          seconds < 10 ? '0' : ''
        }${seconds}`
      );
    }
    if (videoRef.current && videoRef.current.currentTime > trimEndTime) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleGoBack = () => {
    if (selected) {
      dispatch.video.setMedia({ id: selected?.id, value: null });
    }
    navigate(-1);
  };

  const handleTrim = async () => {
    if (!videoSrc) return;

    const fileName = 'input.mp4';
    const trimmedFileName = 'trimmed.mp4';

    ffmpeg.writeFile(fileName, await fetchFile(videoSrc));
    await ffmpeg.exec([
      '-i',
      fileName,
      '-ss',
      `${trimStartTime}`,
      '-to',
      `${trimEndTime}`,
      '-c',
      'copy',
      trimmedFileName,
    ]);
    const data = await ffmpeg.readFile(trimmedFileName);
    const blob = new Blob([data], { type: 'video/mp4' });
    const file = new File([blob], 'video.mp4', {
      type: 'video/mp4',
    });
    if (selected) {
      dispatch.video.setMedia({ id: selected?.id, value: file });
    }
    // const url = URL.createObjectURL(blob);
    // setVideoSrc(url);
    // Clean up FFmpeg virtual files
    ffmpeg.deleteFile(fileName);
    ffmpeg.deleteFile(trimmedFileName);
    // const video = document.createElement('video');
    // video.src = url;
    // video.onloadedmetadata = () => {
    //   setVideoDuration(video.duration);
    //   setEndTime(video.duration);
    // };
    // setDragPosition({ x: 0, y: 0 });
    navigate(-1);
  };

  const handlePlayVideo = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        setIsPlaying(false);
        return;
      }
      videoRef.current.currentTime = trimStartTime;
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const onTimeUpdate = () => {
    if (videoRef.current) {
      if (videoRef.current.currentTime > trimEndTime) {
        videoRef.current.pause();
      }
      handleTimeUpdate();
    }
  };

  const handleCaptureFrames = async () => {
    if (canvasRef.current && frameVideoRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const video = frameVideoRef.current;
      const duration = video.duration;
      const totalFrames = 8;
      const framesArray: any = [];
      const interval = duration / (totalFrames - 1);
      canvas.width = frameVideoRef.current.videoWidth;
      canvas.height = frameVideoRef.current.videoHeight;
      for (let i = 0; i < totalFrames; i++) {
        const time = i * interval;
        video.currentTime = time;
        await new Promise(resolve => {
          video.onseeked = () => {
            context!.drawImage(video, 0, 0, canvas.width, canvas.height);
            framesArray.push(canvas.toDataURL('image/png'));
            // @ts-ignore
            resolve();
          };
        });
      }
      setFrames(framesArray);
    }
  };

  useEffect(() => {
    if (!ffmpeg.loaded) {
      console.log('FFMPEG not Loaded');
      console.log('Loading FFMPEG');
      ffmpeg
        .load()
        .then(() => {
          console.log('FFMPEG loaded');
          setLoading(false);
        })
        .catch((err: any) => console.log(err));
    } else {
      setLoading(false);
    }
  }, []);

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    return `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${secs < 10 ? `0${secs}` : secs}`;
  };

  useEffect(() => {
    if (videoDuration) {
      if (parentRef.current) {
        const ratio = Math.ceil((+selected!.duration / videoDuration) * 100);
        setTrimStartTime(0);
        if (videoDuration < +selected!.duration) {
          setTrimEndTime(videoDuration);
          setDragWidth(100);
        } else {
          setDragWidth(ratio);
          setTrimEndTime(+selected!.duration);
        }
      }
    }
    // eslint-disable-next-line
  }, [videoDuration]);

  useEffect(() => {
    const v = data.find(item => item.id === selected?.id);
    if (v && v.media) {
      const videoUrl = URL.createObjectURL(v.media);
      setVideoSrc(videoUrl);
      dispatch.video.setSelected(v);

      const video = document.createElement('video');
      video.src = videoUrl;
      video.onloadedmetadata = () => {
        setVideoDuration(video.duration);
        setEndTime(video.duration);
      };
    }
    // eslint-disable-next-line
  }, [data, selected]);

  return (
    <div className='flex-grow  w-full 2xl:max-w-[620px] max-w-[410px] 2xl:pb-[100px] pb-2 mx-auto '>
      <div className='h-full relative bg-[#00000066] aspect-[0.75/1]'>
        {videoSrc && (
          <>
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            <video
              ref={videoRef}
              src={videoSrc}
              className='h-full w-full object-contain'
              onTimeUpdate={onTimeUpdate}
            />
            <video
              src={videoSrc}
              ref={frameVideoRef}
              className='hidden'
              onLoadedData={handleCaptureFrames}
            ></video>
          </>
        )}
        <div
          className='absolute left-[50%] top-[50%] transform translate-x-[-50%] translate-y-[-50%] size-10 rounded-full grid place-items-center bg-[#0000004d] cursor-pointer'
          onClick={handlePlayVideo}
        >
          <img
            src={isPlaying ? ASSETS.pause : ASSETS.play}
            className=' w-5 shadow-2xl '
            alt=''
          />
        </div>
        <div className='absolute w-full top-0 left-0 2xl:px-6 px-3 2xl:pt-7 pt-5'>
          <div className='flex items-center justify-between'>
            <div
              className='2xl:size-[54px] size-[35px] rounded-full bg-[#0000001a] grid place-items-center cursor-pointer'
              onClick={handleGoBack}
            >
              <img src={ASSETS.cross} className='w-5 2xl:w-auto' alt='' />
            </div>
            <p className='2xl:text-32 text-xl text-white font-bold text-center'>
              {selected?.question}
            </p>
            <div className='2xl:size-[54px] size-[35px] rounded-full  grid place-items-center cursor-pointer'>
              {/* <img src={ASSETS.retake} alt='' /> */}
            </div>
          </div>
          <div className='flex items-center justify-between mt-[14px]'>
            <p className='2xl:text-xl text-base font-medium'>
              {formatTime(trimStartTime)}
            </p>
            <p className='2xl:text-xl text-base font-medium'>
              {formatTime(trimEndTime)}
            </p>
          </div>
          <div className='relative' ref={parentRef}>
            <div className='flex items-center gap-[2px]  2xl:h-[85px] h-14 2xl:rounded-[15px] rounded-lg  relative'>
              <div
                className='absolute left-0 translate-x-[-50%] bg-primary 2xl:top-[-60px] top-[-40px] px-3 py-[6px] rounded-3xl before:content-[""] before:absolute before:w-[3px] z-10 2xl:before:h-[120px] before:h-[70px] before:bg-primary before:bottom-0 before:translate-y-[100%] before:left-[calc(50%_-_1.5px)] transition-all  duration-100'
                ref={progressRef}
              >
                <p className='2xl:text-[20px] text-sm text-white font-medium '>
                  {time}
                </p>
              </div>
              {frames.map((frame, index) => (
                <div
                  className='flex-1 h-full rounded-md overflow-hidden'
                  key={index}
                >
                  <img
                    key={index}
                    src={frame}
                    alt={`frame-${index}`}
                    className='h-full w-full object-cover'
                  />
                </div>
              ))}
            </div>
            {dragWidth > 0 && (
              <Draggable
                axis='x'
                bounds='parent'
                onDrag={handleDrag}
                position={dragPosition}
                onStop={handleDragStop}
              >
                <div
                  className={`h-full w-[${dragWidth}%] border-[3px] border-white absolute top-0 left-0 cursor-move 2xl:rounded-[12px] rounded-lg before:content-[""] before:absolute before:top-[50%] before:left-0 before:translate-x-[-60%] before:translate-y-[-50%] before:w-[10px] before:h-[10px] before:bg-white before:rounded-full
              after:absolute after:top-[50%] after:right-0 after:translate-x-[60%] after:translate-y-[-50%] after:w-[10px] after:h-[10px] after:bg-white after:rounded-full
              `}
                  style={{ width: `${dragWidth}%` }}
                ></div>
              </Draggable>
            )}
          </div>
        </div>
        <div className='absolute w-full bottom-0 left-0 2xl:px-6 px-4 2xl:pb-9 pb-5'>
          <Button
            label={t('Upload')}
            className='w-full 2xl:!text-22 !text-lg'
            onClick={handleTrim}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoTrimmer;
