import { FC, useState } from 'react';

import Phone, { CountryData } from 'react-phone-input-2';
import styled from 'styled-components';
import 'react-phone-input-2/lib/material.css';
import theme from 'theme';
import ASSETS from 'assets';
import { t } from 'i18next';

interface IProps {
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  error?: string;
}

const PhoneInput: FC<IProps> = ({ phone, setPhone, error }) => {
  const [val, setVal] = useState<string>('');
  const handleChange = (val: string, country: CountryData) => {
    if (country.format) {
      const { format, dialCode } = country;
      setPhone(`${format[0]}${dialCode}${val}`);
      setVal(val);
    }
  };
  return (
    <Container>
      <Phone
        onChange={handleChange}
        value={val}
        onlyCountries={['us']}
        buttonClass='btn'
        disableDropdown
        specialLabel=''
        disableCountryCode
        country={'us'}
        buttonStyle={{ border: `1px solid ${theme.colors['grey-400']}` }}
        containerClass='container'
        inputProps={{
          placeholder: t('Phone number'),
          type: 'string',
        }}
      />
      {error && (
        <div className='flex items-center gap-1 mt-1'>
          <img src={ASSETS.errorInfo} alt='' />
          <p className='text-xs text-error'>{error}</p>
        </div>
      )}
    </Container>
  );
};

export default PhoneInput;

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  & .react-tel-input {
    display: flex;
    position: initial;
    flex-direction: row-reverse;
    gap: 14px;
    width: 100%;

    & .flag-dropdown {
      position: initial;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.colors['grey-400']};
      /* width: 61px; */
      background-color: ${({ theme }) => theme.colors.white};
    }

    & .arrow {
      margin: 0px;
      left: 31px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid ${({ theme }) => theme.colors.black};
    }
  }
  & .form-control {
    height: 48px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors['grey-400']};
    position: unset;
    border-radius: 8px;
    padding-inline: 16px 27px;
    flex-grow: 1;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
    &::placeholder {
      color: ${({ theme }) => theme.colors.black};
    }
  }
  & .form-control:hover {
    border: 1px solid ${({ theme }) => theme.colors['grey-400']};
  }
  & .form-control:focus {
    border-color: ${({ theme }) => theme.colors.secondary};
    box-shadow: 0 0 0 1px transparent;
  }
`;
