import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import ASSETS from 'assets';
import { ClipLoader } from 'react-spinners';
import theme from 'theme';

const ReportedJobs = () => {
  const dispatch = useDispatch<Dispatch>();

  const { loading, reportedJobs } = useSelector(
    (state: RootState) => state.reports
  );

  useEffect(() => {
    dispatch.reports.handleGetReportedJobs();
    // eslint-disable-next-line
  }, []);

  return (
    <div className='flex-grow px-2 2xl:mt-11 mt-6 h-0 overflow-auto flex flex-col 2xl:gap-4 gap-3 pb-3'>
      {reportedJobs.length === 0 && loading && (
        <div className='py-10 flex items-center justify-center'>
          <ClipLoader size={50} color={theme.colors.primary} />
        </div>
      )}
      {reportedJobs?.map(job => (
        <div
          key={job.id}
          className='flex 2xl:gap-[29px] gap-4 items-center rounded-lg shadow-mm min-h-[96px] px-3 overflow-auto bg-white'
        >
          <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
            <img
              src={job.company_logo || ASSETS.preview}
              className='w-full h-full object-cover'
              alt=''
            />
          </div>
          <div>
            <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
              {job.company_name}
            </p>
            <p className='2xl:text-lg text-base font-medium leading-6'>
              {job.title}
            </p>
            <p className='2xl:text-base text-sm'>{job.location}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReportedJobs;
