import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import NotFound from './NotFound';
import Button from 'components/Button/Button';

import ASSETS from 'assets';
import { useEffect } from 'react';
import { t } from 'i18next';

const CONFIG = [
  {
    img: ASSETS.job1,
    company: 'Wardiere Software',
    job: 'UX UI Designer ',
    location: 'Florida, United States',
  },
  {
    img: ASSETS.job2,
    company: 'Dragon Zone',
    job: 'Graphic Designer ',
    location: 'Florida, United States',
  },
  {
    img: ASSETS.job3,
    company: 'Gamer Zone',
    job: 'Viusal Designer ',
    location: 'Florida, United States',
  },
  {
    img: ASSETS.job4,
    company: 'Skull Expert ',
    job: 'Graphic Designer ',
    location: 'Florida, United States',
  },
  {
    img: ASSETS.job6,
    company: 'Crabite Games',
    job: 'Graphic Designer ',
    location: 'Florida, United States',
  },
];

const JobsViewed = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { viewedJobs } = useSelector((state: RootState) => state.myjobs);

  const handleClick = (id: string) => {
    navigate(`/job/${id}`);
  };

  useEffect(() => {
    dispatch.myjobs.handleGetViewedJobs();
  }, []);
  return (
    <Container className='2xl:pt-[33px] pt-5'>
      <JobsSection>
        {viewedJobs.length === 0 && (
          <NotFound
            message={t('You have not viewed any jobs yet')}
            icon={ASSETS.eyeYellow}
            children={
              <Button
                label={t('Explore Jobs')}
                variant='outlined'
                onClick={() => navigate('/explore')}
              />
            }
          />
        )}
        {viewedJobs.map((job, i) => (
          <Job
            key={i}
            className='rounded-lg 2xl:gap-[29px] gap-4 shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white cursor-pointer'
            onClick={() => handleClick(job.job_id)}
          >
            <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
              <img src={job.m_company_logo || ASSETS.preview} alt='' />
            </div>
            <div>
              <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                {job.m_company_name}
              </p>
              <p className='2xl:text-lg text-base font-medium leading-6'>
                {job.m_job_title}
              </p>
              <p className='2xl:text-base text-sm'>{job.m_company_location}</p>
            </div>
          </Job>
        ))}
      </JobsSection>
    </Container>
  );
};

export default JobsViewed;

const Container = styled.div`
  max-width: 820px;
  width: 100%;
  margin-inline: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const JobsSection = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Job = styled.div`
  display: flex;
  align-items: center;
`;
