import Post from './components/Post/Post';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import Applicant from './components/Post/Applicant';
import { useEffect } from 'react';
import { employerRoles } from 'app.config';
import { t } from 'i18next';

const Explore = () => {
  const dispatch = useDispatch<Dispatch>();

  const { user } = useSelector((state: RootState) => state.auth);
  const { loading, posts } = useSelector((state: RootState) => state.posts);
  const {
    sortBy,
    location,
    jobTitle,
    tags,
    jobLevel,
    companies,
    areaOfExperties,
    max,
    min,
    filterApplied,
  } = useSelector((state: RootState) => state.filters);

  useEffect(() => {
    let query = '?';
    if (sortBy) query += `&sortBy=${sortBy}`;
    if (location.length) query += `&location=${location}`;
    if (jobTitle.length) query += `&jobTitle=${jobTitle}`;
    if (tags.length) query += `&tags=${tags}`;
    if (jobLevel.length) query += `&jobLevel=${jobLevel}`;
    if (companies.length) query += `&companies=${companies}`;
    if (areaOfExperties.length) query += `&areaOfExperties=${areaOfExperties}`;
    if (!employerRoles.includes(user?.role || '')) {
      if (filterApplied >= 1)
        query += `&salaryRange=${min * 1000}-${max * 1000}`;
    }
    dispatch.posts.handleGetVideos(query);
    // eslint-disable-next-line
  }, [user, filterApplied]);

  return (
    <div className='flex-grow overflow-auto w-full '>
      {!loading && (
        <>
          {posts.length > 0 ? (
            <>
              {posts.map((post, index) => {
                if (post.videoType === 1) {
                  return <Post key={post.id} post={post} index={index} />;
                } else {
                  return <Applicant key={post.id} post={post} index={index} />;
                }
              })}
            </>
          ) : (
            <>
              <div className='flex justify-center items-center h-full py-10'>
                <p className='text-3xl font-medium text-secondary'>
                  {t('No Results Found')}
                </p>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Explore;
