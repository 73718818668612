import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import ASSETS from 'assets';
import { applicantRoles } from 'app.config';
import { Chat } from 'store/models/chat';
import classNames from 'classnames';

dayjs.extend(utc);

const Chats = () => {
  const dispatch = useDispatch<Dispatch>();

  const [isApplicant, setIsApplicant] = useState(false);

  const { user } = useSelector((state: RootState) => state.auth);
  const { chats, selected } = useSelector((state: RootState) => state.chat);

  const handleSelectChat = (c: Chat) => {
    dispatch.chat.setSelected(c);
  };

  useEffect(() => {
    if (user) {
      if (applicantRoles.includes(user.role || '')) {
        setIsApplicant(true);
      } else {
        setIsApplicant(false);
      }
    }
  }, [user]);

  return (
    <div className='flex-grow  overflow-auto h-0'>
      {chats.map(c => (
        <div
          className={classNames(
            'w-full 2xl:px-[25px] px-4 2xl:h-[104px] h-[90px] flex items-center justify-between gap-2 border-b-[0.5px] border-b-grey-600 first-of-type:border-t-[0.5px] first-of-type:border-t-grey-600',
            selected?.group_id === c.group_id && 'bg-grey-200'
          )}
          onClick={() => handleSelectChat(c)}
        >
          <div className='flex items-center flex-grow gap-[10px]'>
            <img
              src={
                isApplicant
                  ? c.company_logo || ASSETS.preview
                  : c.image_url || ASSETS.preview
              }
              className='2xl:size-[55px] size-[50px] rounded-full object-cover'
              alt=''
            />
            <div className='flex-grow'>
              <p className='2xl:text-lg text-base font-medium truncate max-w-[35ch]'>
                {isApplicant
                  ? c.name
                  : c.user_name
                  ? c.user_name
                  : `${c.first_name} ${c.last_name}`}
              </p>
              {!isApplicant && user?.role !== 'E' && (
                <span className='text-sm font-light'>
                  {' '}
                  By {c.m_company_name}
                </span>
              )}
              <p className='text-xs text-placeholder mt-[5px] line-clamp-2'>
                {c.lastMsg?.type === 'txt' && c.lastMsg?.msg}
                {c.lastMsg?.type === 'img' && '📸 Photo'}
                {c.lastMsg?.type === 'file' && '📁 File'}
              </p>
            </div>
            <div className='flex flex-col items-end '>
              {c.lastMsg && c.lastMsg.time && (
                <p className='text-xs whitespace-nowrap mt-[-5px]'>
                  {dayjs.utc(c.lastMsg.time).format('ddd MMM, DD')}
                </p>
              )}
              {c.unreadCount > 0 && c.group_id !== selected?.group_id && (
                <div className='text-xs font-medium text-white h-[28px] min-w-[28px] rounded-full mt-4 bg-primary grid place-items-center'>
                  <span className='text-inherit'>{c.unreadCount}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}

      {/* <div className='w-full 2xl:px-[25px] px-4 2xl:h-[104px] h-[90px] flex items-center justify-between gap-2 border-b-[0.5px] border-b-grey-600 bg-grey-200'>
        <div className='flex items-center flex-grow gap-[10px]'>
          <img
            src={ASSETS.company}
            className='2xl:size-[55px] size-[50px]'
            alt=''
          />
          <div>
            <p className='2xl:text-lg text-base font-medium'>
              Dragon Zone Studio
            </p>
            <p className='text-xs text-placeholder mt-[5px]'>
              Can’t wait to hear from you soon, Acknowledge our final round.{' '}
            </p>
          </div>
          <div className='flex flex-col items-end '>
            <p className='text-xs whitespace-nowrap mt-[-5px]'>Yesterday</p>
            <div className='text-xs font-medium text-white h-[28px] min-w-[28px] rounded-full mt-4 bg-transparent grid place-items-center'>
              <span className='text-inherit'></span>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Chats;
