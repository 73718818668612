import { useEffect, useState } from 'react';

import classNames from 'classnames';
import BlockedUser from './BlockedUsers';
import ReportedUsers from './ReportedUsers';
import ReportedJobs from './ReportedJobs';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { applicantRoles, employerRoles } from 'app.config';
import ReportedComapnies from './ReportedComapnies';

type TAB =
  | 'blocked-users'
  | 'reported-users'
  | 'reported-jobs'
  | 'reported-companies';

const BlockAndReport = () => {
  const [tab, setTab] = useState<TAB>('blocked-users');

  const handleTabChange = (tab: TAB) => {
    setTab(tab);
  };

  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (user) {
      if (applicantRoles.includes(user.role || '')) {
        setTab('reported-companies');
      }
    }
  }, [user]);

  return (
    <div className='2xl:px-6 px-4 2xl:py-9 py-5 flex flex-col flex-grow'>
      <p className='2xl:text-2xl text-lg font-bold'>{t('Block & Report')}</p>
      <div className='tabs flex items-center gap-[9px] 2xl:mt-[29px] mt-4'>
        {employerRoles.includes(user?.role || '') && (
          <>
            <div
              className={classNames(
                'tab px-[35px] flex justify-center items-center h-10  text-sm font-medium bg-grey-100 border-[0.5px] border-grey-600 rounded-3xl cursor-pointer transition-all duration-300',
                tab === 'blocked-users' &&
                  'bg-primary text-white border-primary'
              )}
              onClick={() => handleTabChange('blocked-users')}
            >
              <p className='text-inherit'>{t('Blocked Users')}</p>
            </div>
            <div
              className={classNames(
                'tab px-[35px] flex justify-center items-center h-10  text-sm font-medium bg-grey-100 border-[0.5px] border-grey-600 rounded-3xl cursor-pointer transition-all duration-300',
                tab === 'reported-users' &&
                  'bg-primary text-white border-primary'
              )}
              onClick={() => handleTabChange('reported-users')}
            >
              <p className='text-inherit'>{t('Reported Users')}</p>
            </div>
          </>
        )}

        {applicantRoles.includes(user?.role || '') && (
          <>
            <div
              className={classNames(
                'tab px-[35px] flex justify-center items-center h-10  text-sm font-medium bg-grey-100 border-[0.5px] border-grey-600 rounded-3xl cursor-pointer transition-all duration-300',
                tab === 'reported-companies' &&
                  'bg-primary text-white border-primary'
              )}
              onClick={() => handleTabChange('reported-companies')}
            >
              <p className='text-inherit'>{t('Reported Companies')}</p>
            </div>
            <div
              className={classNames(
                'tab px-[35px] flex justify-center items-center h-10  text-sm font-medium bg-grey-100 border-[0.5px] border-grey-600 rounded-3xl cursor-pointer transition-all duration-300',
                tab === 'reported-jobs' &&
                  'bg-primary text-white border-primary'
              )}
              onClick={() => handleTabChange('reported-jobs')}
            >
              <p className='text-inherit'>{t('Reported Jobs')}</p>
            </div>
          </>
        )}
      </div>
      {tab === 'blocked-users' && <BlockedUser />}
      {tab === 'reported-users' && <ReportedUsers />}
      {tab === 'reported-jobs' && <ReportedJobs />}
      {tab === 'reported-companies' && <ReportedComapnies />}
    </div>
  );
};

export default BlockAndReport;
