import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import ASSETS from 'assets';
import { ClipLoader } from 'react-spinners';
import theme from 'theme';
// import NotFound from 'pages/MyJobs/components/NotFound';

const ReportedComapnies = () => {
  const dispatch = useDispatch<Dispatch>();

  const { loading, reportedCompanies } = useSelector(
    (state: RootState) => state.reports
  );

  useEffect(() => {
    dispatch.reports.handleGetReportedCompanies();
    // eslint-disable-next-line
  }, []);
  return (
    <div className='flex-grow px-2 2xl:mt-11 mt-6 h-0 overflow-auto flex flex-col 2xl:gap-4 gap-3 pb-3'>
      {reportedCompanies.length === 0 && loading && (
        <div className='py-10 flex items-center justify-center'>
          <ClipLoader size={50} color={theme.colors.primary} />
        </div>
      )}
      {reportedCompanies?.map(c => (
        <div className='flex items-center gap-5'>
          <img
            src={c.company_logo || ASSETS.preview}
            className='2xl:size-[50px] size-[45px] object-cover rounded-full'
            alt=''
          />
          <p className='text-lg font-medium'>{c.name}</p>
        </div>
      ))}
      {/* <NotFound message='' /> */}
    </div>
  );
};

export default ReportedComapnies;
