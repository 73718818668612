import React, { useState } from 'react';

import { Range, getTrackBackground } from 'react-range';
import { Navigate, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { object, string, array, InferType } from 'yup';

import Select from 'components/Select/Select';
import { SelectOption } from 'types/types';
import TextField from 'components/TextField/TextField';
import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import useCurrentBreakpoint from 'hooks/useCurrentBreakpoint';
import QuilEditor from 'components/QuillEditor/QuilEditor';
import JobTagsSelector from 'components/JobTagsSelector/JobTagsSelector';
import JobTitleSelector from 'components/JobTitleSelector/JobTitleSelector';
import { t } from 'i18next';

const JobTypes: SelectOption[] = [
  {
    value: 'Remote',
    label: 'Remote',
  },
  {
    value: 'Hybrid',
    label: 'Hybrid',
  },
  {
    value: 'Onsite',
    label: 'Onsite',
  },
];

const schema = object({
  job_type: string().required('Required field').label('Job type'),
  title: string().required('Required field').label('Job title'),
  description: string()
    .required('Required field')
    .max(2000)
    .label('Job description'),
  tags: array()
    .of(string())
    .min(1, 'Required field')
    .required('Required field')
    .label('Tags'),
  external_link: string()
    .url('Invalid External link')
    .optional()
    .label('External link'),
});

export interface IJobValues extends InferType<typeof schema> {}

const CreateJob = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { selected } = useSelector((state: RootState) => state.companies);

  const breakpoint = useCurrentBreakpoint();

  const [sallary, setValues] = useState<Array<number>>([10, 500]);

  const handleNext = () => {
    navigate('/create-job/questions');
  };

  const formik = useFormik<IJobValues>({
    initialValues: {
      job_type: '',
      title: '',
      description: '',
      tags: [],
      external_link: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      console.log({
        ...values,
        salary_from: sallary[0],

        salary_to: sallary[1],
      });
      dispatch.job.setDetails({
        ...values,
        salary_from: sallary[0] * 1000,

        salary_to: sallary[1] * 1000,
      });
      handleNext();
    },
  });

  const handleSelectTag = (val: string) => {
    const tags = formik.values.tags;
    // @ts-ignore
    if (tags.includes(val)) {
      formik.setFieldValue(
        'tags',
        tags.filter(tag => tag !== val)
      );
    } else {
      formik.setFieldValue('tags', [...tags, val]);
    }
  };

  if (!selected) {
    return <Navigate to='/explore' replace />;
  }

  return (
    <div className='flex-grow'>
      <div className='mx-auto w-full max-w-[776px] bg-white border-[0.5px] border-grey-600 rounded-[12px] 2xl:px-11 px-7 2xl:pt-[70px] pt-[20px] 2xl:pb-[56px] pb-5'>
        <div className='flex flex-col 2xl:gap-[30px] gap-4'>
          <Select
            selected=''
            options={JobTypes}
            label=''
            onChange={val => {
              formik.setFieldValue('job_type', val);
            }}
            containerClassName='2xl:!h-[70px]'
            error={
              formik.touched.job_type && formik.errors.job_type
                ? formik.errors.job_type
                : ''
            }
            placeholder='Job Type*'
          />
          <JobTitleSelector
            value={formik.values.title}
            onChange={val => formik.setFieldValue('title', val)}
            error={
              formik.touched.title && formik.errors.title
                ? (formik.errors.title as string)
                : ''
            }
          />

          <div className=''>
            <QuilEditor
              value={formik.values.description}
              error={
                formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : ''
              }
              onChange={val => formik.setFieldValue('description', val)}
            />
          </div>
          <div className='flex items-center gap-3 mb-5 '>
            <Checkbox />
            <p className='text-lg font-medium'>{t('Salary Range')} </p>
          </div>
          <div className='h-[70px] mt-3 px-3'>
            <Range
              values={sallary}
              onChange={values => setValues(values)}
              min={10}
              max={500}
              step={5}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: breakpoint === 'xl' ? '8px' : '12px',
                    width: '100%',
                    background: getTrackBackground({
                      values: sallary,
                      colors: ['#DAD9DB', '#008080', '#DAD9DB'],
                      min: 10,
                      max: 500,
                    }),
                    borderRadius: breakpoint === 'xl' ? '10px' : '16px',
                    alignSelf: 'center',
                  }}
                >
                  {children}
                  <div className='flex justify-between 2xl:mt-[16px] mt-3'>
                    <span className='2xl:text-lg text-base text-placeholder mt-[26px]'>
                      $10k
                    </span>
                    <span className='2xl:text-lg text-base text-placeholder mt-[26px]'>
                      $500k
                    </span>
                  </div>
                </div>
              )}
              renderThumb={({ props, isDragged, index }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                  }}
                  className='2xl:size-9 size-7 outline-none rounded-full bg-primary flex justify-center'
                >
                  <div className='absolute 2xl:top-[-40px] top-[-30px] text-black font-medium 2xl:text-lg text-base'>
                    {`$${sallary[index]}`}k
                  </div>
                </div>
              )}
            />
          </div>
          <div className='mt-[-10px]'>
            <JobTagsSelector
              selected={formik.values.tags as string[]}
              onChange={val => handleSelectTag(val)}
              error={
                formik.touched.tags && formik.errors.tags
                  ? (formik.errors.tags as string)
                  : ''
              }
            />
          </div>
          <div>
            <TextField
              containerClassName='2xl:!h-[70px]'
              inputClassName='2xl:!text-lg'
              placeholder={`${t('External Link')} ${t('(Optional)')}`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.external_link}
              name='external_link'
              error={
                formik.touched.external_link && formik.errors.external_link
                  ? formik.errors.external_link
                  : ''
              }
            />
          </div>
          <Button
            label={t('Next')}
            className='2xl:!text-22 !text-lg 2xl:mt-[40px] mt-[20px]'
            type='submit'
            onClick={() => formik.handleSubmit()}
          />
        </div>
      </div>
      <div className='h-10'></div>
    </div>
  );
};

export default CreateJob;
