import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  IAppliedJob,
  IReceivedJobsData,
  ISavedJob,
  IViewedJobs,
} from 'types/interfaces';
import {
  appliedJobs,
  getSavedJobs,
  receivedJobApplications,
  viewesJobs,
} from 'http/jobService';
import { getSavedProfiles } from 'http/userService';

interface IState {
  loading: boolean;
  savedJobs: ISavedJob[];
  appliedJobs: IAppliedJob[];
  viewedJobs: IViewedJobs[];
  savedProfiles: any[];
  receivedJobs: null | IReceivedJobsData;
  applications: number;
  selectedJobTitle: string;
}

export const myjobs = createModel<RootModel>()({
  name: 'myjobs',
  state: {
    loading: false,
    savedJobs: [],
    appliedJobs: [],
    viewedJobs: [],
    savedProfiles: [],
    receivedJobs: null,
    applications: 0,
    selectedJobTitle: '',
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setSavedJobs(state, payload: ISavedJob[]) {
      state.savedJobs = payload;
    },
    setAppliedJobs(state, payload: IAppliedJob[]) {
      state.appliedJobs = payload;
    },
    setViewedJobs(state, payload: IViewedJobs[]) {
      state.viewedJobs = payload;
    },
    setSavedProfiles(state, payload: any[]) {
      state.savedProfiles = payload;
    },
    setReceivedJobs(state, payload: IReceivedJobsData | null) {
      state.receivedJobs = payload;
    },
    setApplications(state, payload: number) {
      state.applications = payload;
    },
    setSelectedJobTitle(state, payload: string) {
      state.selectedJobTitle = payload;
    },
  },
  effects: dispatch => ({
    async handleGetSavedJobs() {
      try {
        dispatch.myjobs.setLoading(true);
        const { data } = await getSavedJobs();
        dispatch.myjobs.setSavedJobs(data.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.myjobs.setLoading(false);
      }
    },
    async handleGetAppliedJobs() {
      try {
        dispatch.myjobs.setLoading(true);
        const { data } = await appliedJobs();
        dispatch.myjobs.setAppliedJobs(data.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.myjobs.setLoading(false);
      }
    },
    async handleGetViewedJobs() {
      try {
        dispatch.myjobs.setLoading(true);
        const { data } = await viewesJobs();
        dispatch.myjobs.setViewedJobs(data.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.myjobs.setLoading(false);
      }
    },
    async handleGetSavedProfiles() {
      try {
        dispatch.myjobs.setLoading(true);
        const { data } = await getSavedProfiles();
        dispatch.myjobs.setSavedProfiles(data.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.myjobs.setLoading(false);
      }
    },
    async handleGetReceivedJobs() {
      try {
        dispatch.myjobs.setLoading(true);
        const { data } = await receivedJobApplications();
        dispatch.myjobs.setApplications(data.data.length);
        const groupedData: IReceivedJobsData = data.data.reduce(
          (acc: IReceivedJobsData, item) => {
            const key = item.m_job_title;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          },
          {}
        );
        dispatch.myjobs.setReceivedJobs(groupedData);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.myjobs.setLoading(false);
      }
    },
  }),
});
