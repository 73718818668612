import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { string, object } from 'yup';

import Button from 'components/Button/Button';
import Select from 'components/Select/Select';
import TextField from 'components/TextField/TextField';
import { racialBackgorund } from 'app.config';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { useFormik } from 'formik';
import { JobDetails } from 'types/interfaces';
import { t } from 'i18next';

const options = ['Yes', 'No', 'Prefer not to say'];

const Questions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [schema, setSchema] = useState(object().shape({}));
  const [initialValues, setInitialValues] = useState<JobDetails>({});
  const [errors, setErrors] = useState<{ [x: string]: string }>({});
  const [trigered, setTrigered] = useState(false);

  const { job } = useSelector((state: RootState) => state.job);

  useEffect(() => {
    if (job?.job_detail) {
      const s: { [x: string]: any } = {};
      const initialVals: { [x: string]: any } = {};

      if (job.job_detail.isUSAuthorized) {
        s['isUSAuthorized'] = string().required('Authorization is required');
        initialVals['isUSAuthorized'] = ''; // Initial value for the form field
      }
      if (job.job_detail.isSponsorWorkVisa) {
        s['isSponsorWorkVisa'] = string().required(
          'Work Visa sponsorship is required'
        );
        initialVals['isSponsorWorkVisa'] = '';
      }
      if (job.job_detail.isDisability) {
        s['isDisability'] = string().required('Disability status is required');
        initialVals['isDisability'] = '';
      }
      if (job.job_detail.isVeteran) {
        s['isVeteran'] = string().required('Veteran status is required');
        initialVals['isVeteran'] = '';
      }
      if (job.job_detail.isRacialBackground) {
        s['isRacialBackground'] = string().required(
          'Racial background is required'
        );
        initialVals['isRacialBackground'] = '';
      }
      if (job.job_detail.isFormerEmployee) {
        s['isFormerEmployee'] = string().required(
          'Former employment status is required'
        );
        initialVals['isFormerEmployee'] = '';
      }
      if (job.job_detail.isAffiliateCompany) {
        s['isAffiliateCompany'] = string().required(
          'Affiliate company status is required'
        );
        initialVals['isAffiliateCompany'] = '';
      }
      if (job.job_detail.isReferred) {
        s['isReferred'] = string().required('Referral status is required');
        initialVals['isReferred'] = '';
      }
      if (job.job_detail.is_referral_name) {
        s['is_referral_name'] = string().required('Referral name is required');
        initialVals['is_referral_name'] = '';
      }
      if (job.job_detail.is_referral_email) {
        s['is_referral_email'] = string()
          .email('Invalid email format')
          .required('Referral email is required');
        initialVals['is_referral_email'] = '';
      }

      if (Object.keys(initialVals).length === 0) {
        return navigate('/apply/salery-range');
      }

      setSchema(object().shape(s));
      setInitialValues(initialVals);
    }
    // eslint-disable-next-line
  }, [job]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: values => {
      dispatch.job.setAppyJobQuestionDetails(values);
      navigate('/apply/salery-range');
    },
  });

  useEffect(() => {
    setErrors({ ...formik.errors });

    // eslint-disable-next-line
  }, [formik.errors]);

  return (
    <div className='w-full flex-grow'>
      <div className='w-full max-w-[821px] mx-auto flex flex-col gap-3'>
        {(job?.job_detail.isUSAuthorized ||
          job?.job_detail.isSponsorWorkVisa) && (
          <div className='bg-white border-[0.5px] border-grey-600 rounded 2xl:px-7 p-5 2xl:py-11'>
            <div className='flex justify-between items-end'>
              <p className='2xl:text-32 text-2xl text-primary font-medium font-cabin'>
                {t('Work Authorization')}
              </p>
              {/* <span className='text-lg underline text-primary'>Skip</span> */}
            </div>
            {job.job_detail.isUSAuthorized && (
              <div className='2xl:mt-[22px] mt-4'>
                <Select
                  selected={formik.values.isUSAuthorized || ''}
                  onChange={val => {
                    formik.setFieldValue('isUSAuthorized', val);
                    setErrors(prev => ({ ...prev, isUSAuthorized: '' }));
                  }}
                  label={t('Are you authorized to work in the United States?')}
                  options={options.map(o => ({ label: o, value: o }))}
                  error={trigered ? errors?.isUSAuthorized : ''}
                />
              </div>
            )}
            {job.job_detail.isSponsorWorkVisa && (
              <div className='2xl:mt-9 mt-5'>
                <Select
                  selected={formik.values.isSponsorWorkVisa || ''}
                  onChange={val => {
                    formik.setFieldValue('isSponsorWorkVisa', val);
                    setErrors(prev => ({ ...prev, isSponsorWorkVisa: '' }));
                  }}
                  label={t(
                    'Do you now or in the future require a working visa sponsorship?'
                  )}
                  options={options.map(o => ({ label: o, value: o }))}
                  error={trigered ? errors?.isSponsorWorkVisa : ''}
                />
              </div>
            )}
          </div>
        )}
        {(job?.job_detail?.isDisability ||
          job?.job_detail?.isVeteran ||
          job?.job_detail.isRacialBackground) && (
          <div className='bg-white border-[0.5px] border-grey-600 rounded 2xl:px-7 p-5 2xl:py-11'>
            <div className='flex justify-between items-end'>
              <p className='2xl:text-32 text-2xl text-primary font-medium font-cabin'>
                {t('Diversity & Inclusion')}
              </p>
              {/* <span className='text-lg underline text-primary'>Skip</span> */}
            </div>
            {job.job_detail.isDisability && (
              <div className='2xl:mt-[22px] mt-4'>
                <Select
                  selected={formik.values.isDisability || ''}
                  onChange={val => {
                    formik.setFieldValue('isDisability', val);
                    setErrors(prev => ({ ...prev, isDisability: '' }));
                  }}
                  label={t('Do you have any disability?')}
                  options={options.map(o => ({ label: o, value: o }))}
                  error={trigered ? errors?.isDisability : ''}
                />
              </div>
            )}
            {job.job_detail.isVeteran && (
              <div className='2xl:mt-[30px] mt-5 '>
                <Select
                  selected={formik.values.isVeteran || ''}
                  onChange={val => {
                    formik.setFieldValue('isVeteran', val);
                    setErrors(prev => ({ ...prev, isVeteran: '' }));
                  }}
                  label={t('Do you identify as a veteran?')}
                  options={options.map(o => ({ label: o, value: o }))}
                  error={trigered ? errors?.isVeteran : ''}
                />
              </div>
            )}
            {job.job_detail.isRacialBackground && (
              <div className='2xl:mt-[30px] mt-5'>
                <Select
                  selected={formik.values.isRacialBackground || ''}
                  onChange={val => {
                    formik.setFieldValue('isRacialBackground', val);
                    setErrors(prev => ({ ...prev, isRacialBackground: '' }));
                  }}
                  label={t('What is your racial background?')}
                  options={racialBackgorund.map(op => ({
                    label: op,
                    value: op,
                  }))}
                  error={trigered ? errors?.isRacialBackground : ''}
                />
              </div>
            )}
          </div>
        )}
        {(job?.job_detail.isFormerEmployee ||
          job?.job_detail.isAffiliateCompany) && (
          <div className='bg-white border-[0.5px] border-grey-600 rounded 2xl:px-7 p-5 2xl:py-11'>
            <div className='flex justify-between items-end'>
              <p className='2xl:text-32 text-2xl text-primary font-medium font-cabin'>
                {t('Employment History')}
              </p>
              {/* <span className='text-lg underline text-primary'>Skip</span> */}
            </div>
            {job.job_detail.isFormerEmployee && (
              <div className='2xl:mt-[22px] mt-4'>
                <Select
                  selected={formik.values.isFormerEmployee || ''}
                  onChange={val => {
                    formik.setFieldValue('isFormerEmployee', val);
                    setErrors(prev => ({ ...prev, isFormerEmployee: '' }));
                  }}
                  label={
                    t('Have you ever worked for') +
                    ' ' +
                    job.company_details.name
                  }
                  options={options.map(o => ({ label: o, value: o }))}
                  error={trigered ? errors?.isFormerEmployee : ''}
                />
              </div>
            )}
            {job.job_detail.isAffiliateCompany && (
              <div className='2xl:mt-[30px] mt-5'>
                <Select
                  selected={formik.values.isAffiliateCompany || ''}
                  onChange={val => {
                    formik.setFieldValue('isAffiliateCompany', val);
                    setErrors(prev => ({ ...prev, isAffiliateCompany: '' }));
                  }}
                  label={
                    t('Have you ever worked for any affiliate of') +
                    ' ' +
                    job.company_details.name
                  }
                  options={options.map(o => ({ label: o, value: o }))}
                  error={trigered ? errors?.isAffiliateCompany : ''}
                />
              </div>
            )}
          </div>
        )}
        {(job?.job_detail.isReferred ||
          job?.job_detail.is_referral_email ||
          job?.job_detail.is_referral_name) && (
          <div className='bg-white border-[0.5px] border-grey-600 rounded 2xl:px-7 p-5 2xl:py-11'>
            <div className='flex justify-between items-end'>
              <p className='2xl:text-32 text-2xl text-primary font-medium font-cabin'>
                {t('Employment Referral')}
              </p>
              {/* <span className='text-lg underline text-primary'>Skip</span> */}
            </div>
            {job.job_detail.isReferred && (
              <div className='mt-[22px]'>
                <Select
                  selected={formik.values.isReferred || ''}
                  onChange={val => {
                    formik.setFieldValue('isReferred', val);
                    setErrors(prev => ({ ...prev, isReferred: '' }));
                  }}
                  label={t('Were you referred by someone from')}
                  options={options.map(o => ({ label: o, value: o }))}
                  error={trigered ? errors?.isReferred : ''}
                />
              </div>
            )}
            {job.job_detail.is_referral_name && (
              <div className='2xl:mt-[30px] mt-5'>
                <TextField
                  value={formik.values.is_referral_name}
                  name='is_referral_name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label={t('Please enter name of your referrer')}
                  placeholder={t('Referrer name')}
                  type='text'
                  error={trigered ? errors?.is_referral_name || '' : ''}
                />
              </div>
            )}
            {job.job_detail.is_referral_email && (
              <div className='2xl:mt-[30px] mt-5 '>
                <TextField
                  value={formik.values.is_referral_email}
                  name='is_referral_email'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label={t('Please enter email of your referrer')}
                  placeholder={t('Referrer email')}
                  type='email'
                  error={trigered ? errors?.is_referral_email : ''}
                />
              </div>
            )}
          </div>
        )}
        <Button
          label={t('Next')}
          className='!w-full 2xl:!text-22 !text-lg 2xl:mt-[56px] mt-10'
          onClick={() => {
            formik.handleSubmit();
            setTrigered(true);
          }}
          type='submit'
        />
        <div className='h-11'></div>
      </div>
    </div>
  );
};

export default Questions;
