// import ASSETS from 'assets';
// import React, { FC } from 'react';
// import { Link } from 'react-router-dom';
// import { ClipLoader } from 'react-spinners';
// import theme from 'theme';
// import { IApplicationData } from 'types/interfaces';
// import { convertNumberToK } from 'utils';

// interface IProps {
//   data: IApplicationData | null;
//   setApplicationId: React.Dispatch<React.SetStateAction<string>>;
// }

// const SelectedApplication: FC<IProps> = ({ data, setApplicationId }) => {
//   return (
//     <>
//       {data ? (
//         <div className=' bg-red w-[calc(100%_-_40px)]'>
//           <p className='2xl:text-xl text-lg font-bold 2xl:mt-9 mt-6'>
//             <span
//               className='cursor-pointer'
//               onClick={() => setApplicationId('')}
//             >
//               Received Applications -&gt;
//             </span>{' '}
//             <Link to={`/other-job-seeker/${data.user_id}`}>
//               {data?.user?.first_name} {data?.user?.last_name}
//             </Link>
//           </p>
//           {data?.cv_url && (
//             <div className='attachment mt-4'>
//               <p className='2xl:text-lg text-base font-medium leading-7'>
//                 Resume
//               </p>
//               <a href={data.cv_url} download target='_blank' rel='noreferrer'>
//                 <div className='border-[0.5px] mt-2 border-placeholderColor 2xl:h-[65px] h-[50px] rounded-lg flex items-center gap-2 px-[20px] bg-white'>
//                   <img
//                     src={
//                       data?.cv_type === 'application/pdf'
//                         ? ASSETS.pdf
//                         : ASSETS.word
//                     }
//                     className='w-6 2xl:w-auto'
//                     alt=''
//                   />
//                   <p className='text-sm font-medium'>
//                     {data?.user_name} ({data?.user.job_title}).
//                     {data?.cv_type === 'application/pdf' ? 'pdf' : 'doc'}
//                   </p>
//                 </div>
//               </a>
//             </div>
//           )}
//           {data?.cover_url && (
//             <div className='attachment mt-[11px]'>
//               <p className='2xl:text-lg text-base font-medium leading-7'>
//                 Cover Letter/ Attachment
//               </p>
//               <a
//                 href={data.cover_url}
//                 download
//                 target='_blank'
//                 rel='noreferrer'
//               >
//                 <div className='border-[0.5px] mt-2 border-placeholderColor 2xl:h-[65px] h-[50px] rounded-lg flex items-center gap-2 px-[20px] bg-white'>
//                   <img
//                     src={
//                       data?.cover_type === 'application/pdf'
//                         ? ASSETS.pdf
//                         : ASSETS.word
//                     }
//                     className='w-6 2xl:w-auto'
//                     alt=''
//                   />
//                   <p className='text-sm font-medium'>
//                     {data?.user_name} ({data?.user.job_title}).
//                     {data?.cover_type === 'application/pdf' ? 'pdf' : 'doc'}
//                   </p>
//                 </div>
//               </a>
//             </div>
//           )}
//           <div className='flex items-center justify-between mt-6'>
//             <p className='text-base font-medium'>Expected Salary Range</p>
//             <p className='text-base font-medium '>
//               ${convertNumberToK(data?.salary_range_from || 0)}-$
//               {convertNumberToK(data?.salary_range_to || 0)}
//             </p>
//           </div>
//           <div className='mt-[21px] mb-[14px] h-[1px] bg-grey-600'></div>
//           <div className='flex flex-col gap-[18px]'>
//             <p className='text-base font-medium leading-5'>
//               Work Authorization
//             </p>
//             <div className='flex justify-between items-center'>
//               <p className='text-base font-medium leading-5'>
//                 Are you authorized to work in the United States?
//               </p>
//               <p className='text-base font-medium leading-5 w-[20ch]'>
//                 {data?.application_detail.isUSAuthorized}
//               </p>
//             </div>
//             <div className='flex justify-between items-center'>
//               <p className='text-base font-medium leading-5'>
//                 Do you now or in the future require a working visa sponsorship?
//               </p>
//               <p className='text-base font-medium leading-5 w-[20ch]'>
//                 {data?.application_detail.isSponsorWorkVisa}
//               </p>
//             </div>
//           </div>
//           <div className='mt-[21px] mb-[14px] h-[1px] bg-grey-600'></div>
//           <div className='flex flex-col gap-[18px]'>
//             <p className='text-base font-medium leading-5'>
//               Diversty & Inclusion
//             </p>
//             <div className='flex justify-between items-center'>
//               <p className='text-base font-medium leading-5'>
//                 Do you have any disability?
//               </p>
//               <p className='text-base font-medium leading-5 w-[20ch]'>
//                 {data?.application_detail.isDisability}
//               </p>
//             </div>
//             <div className='flex justify-between items-center'>
//               <p className='text-base font-medium leading-5'>
//                 Do you identify as a vetaran?
//               </p>
//               <p className='text-base font-medium leading-5 w-[20ch]'>
//                 {data?.application_detail.isVeteran}
//               </p>
//             </div>
//             <div className='flex justify-between items-center'>
//               <p className='text-base font-medium leading-5'>
//                 What is your racial background?
//               </p>
//               <p className='text-base font-medium leading-5 w-[20ch]'>
//                 {data?.application_detail.isRacialBackground}
//               </p>
//             </div>
//           </div>
//           <div className='mt-[21px] mb-[14px] h-[1px] bg-grey-600'></div>
//           <div className='flex flex-col gap-[18px]'>
//             <p className='text-base font-medium leading-5 '>
//               Employment History
//             </p>
//             <div className='flex justify-between items-center'>
//               <p className='text-base font-medium leading-5'>
//                 Have you previously worked for our company?
//               </p>
//               <p className='text-base font-medium leading-5  w-[20ch]'>
//                 {data?.application_detail.isFormerEmployee}
//               </p>
//             </div>
//             <div className='flex justify-between items-center'>
//               <p className='text-base font-medium leading-5'>
//                 Have you worked for any affiliate companies?
//               </p>
//               <p className='text-base font-medium leading-5  w-[20ch]'>
//                 {data?.application_detail.isAffiliateCompany}
//               </p>
//             </div>
//           </div>
//           <div className='mt-[21px] mb-[14px] h-[1px] bg-grey-600'></div>
//           <div className='flex flex-col gap-[18px]'>
//             <p className='text-base font-medium leading-5 '>Referred By</p>
//             <div className='flex justify-between items-center'>
//               <p className='text-base font-medium leading-5'>
//                 Were you referred by someone from our company?
//               </p>
//               <p className='text-base font-medium leading-5  w-[20ch]'>
//                 {data?.application_detail.isReferred}
//               </p>
//             </div>
//             {data?.application_detail.is_referral_name && (
//               <div className='flex justify-between items-center'>
//                 <p className='text-base font-medium leading-5'>Referrer name</p>
//                 <p className='text-base font-medium leading-5 w-[20ch]'>
//                   {data?.application_detail.is_referral_name}
//                 </p>
//               </div>
//             )}
//             {data?.application_detail.is_referral_email && (
//               <div className='flex justify-between items-center'>
//                 <p className='text-base font-medium leading-5'>
//                   Referrer email
//                 </p>
//                 <p className='text-base font-medium leading-5  w-[20ch]'>
//                   {data?.application_detail.is_referral_email}
//                 </p>
//               </div>
//             )}
//           </div>
//           <div className='h-20'></div>
//         </div>
//       ) : (
//         <div className='w-full py-20 flex items-center justify-center '>
//           <ClipLoader size={50} color={theme.colors.primary} />
//         </div>
//       )}
//     </>
//   );
// };

// export default SelectedApplication;

import React, { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
// import { ClipLoader } from 'react-spinners';
// import theme from 'theme';
import { IApplicationData, IReceivedJobs } from 'types/interfaces';
import { convertNumberToK } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { useNavigate } from 'react-router-dom';

import ASSETS from 'assets';
import classNames from 'classnames';
import ClickAwayListener from 'react-click-away-listener';
import { saveUserProfile } from 'http/userService';
import { toast } from 'react-toastify';
import { getJobApplicationById } from 'http/jobService';
interface IProps {
  selectedApplicationId: string;
}

interface JobApplicationProps {
  receivedApplication: IReceivedJobs;
  selectedApplicationId: string;
  index: number;
}

const JobApplication: FC<JobApplicationProps> = ({
  receivedApplication,
  selectedApplicationId,
  index,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const container = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const toggleMenu = () => setOpen(!open);
  const closeMenu = () => setOpen(false);

  const [data, setData] = useState<IApplicationData | null>(null);

  const [saved, setSaved] = useState(false);
  const [, setLoading] = useState(false);
  const [videoSrc, setVideoSrc] = useState('');

  const videoRef = useRef<HTMLVideoElement>(null);

  const handleFetchData = async () => {
    try {
      const { data: res } = await getJobApplicationById(
        receivedApplication.application_id
      );
      setData(res.data);
    } catch (err: any) {}
  };

  const handleSaveProfile = async () => {
    try {
      setLoading(true);
      setSaved(!saved);
      closeMenu();
      await saveUserProfile(data?.user_id!);
      if (saved) {
        toast.success('Profile unsaved successfully!');
      } else {
        toast.success('Profile saved successfully!');
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleReport = () => {
    closeMenu();
    dispatch.utils.openActionDialog({
      open: true,
      title: 'Report profile',
      message: 'Are you sure you want to report this profile?',
      buttonTitle: 'Report',
      onClick: () => {
        if (data) {
          dispatch.reports.setType('user');
          dispatch.reports.setId(data?.user_id);
        }
        navigate('/report');
        dispatch.utils.closeActionDialog();
      },
    });
  };

  const handleLoadData = () => {
    try {
      const videoElement = videoRef.current;

      if (videoElement && index === 0) {
        // Play the video immediately if it is the first video
        setVideoSrc(data?.video.url || '');
        videoElement.play().catch((err: any) => {
          console.log('Immediate video playback failed: ', err);
        });
      }
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (receivedApplication) {
      handleFetchData();
    }
    // eslint-disable-next-line
  }, [receivedApplication]);

  useEffect(() => {
    if (data) {
      if (data.user.is_saved) {
        setSaved(true);
      } else {
        setSaved(false);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (container.current) {
      if (
        selectedApplicationId === receivedApplication.application_id &&
        index > 0
      ) {
        container.current.scrollIntoView({ behavior: 'auto' });
      }
    }
    // eslint-disable-next-line
  }, [container]);

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio >= 0.7) {
          // Set the video source and play
          if (!videoSrc) {
            setVideoSrc(data?.video.url || '');
          }
          (entry.target as HTMLVideoElement).play().catch(err => {
            console.log('Video playback failed: ', err);
            // (entry.target as HTMLVideoElement).muted = true;
            // (entry.target as HTMLVideoElement).play().catch(err => null);
          });
        } else {
          // Pause the video if less than 70% in view
          (entry.target as HTMLVideoElement).pause();
        }
      });
    };

    const observerOptions = {
      root: null, // Use the viewport as the root
      threshold: 0.7, // Trigger when 70% of the video is in view
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );
    const videoElement = videoRef.current;

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
    // eslint-disable-next-line
  }, [videoSrc, data]);

  return (
    <div
      className='h-full overflow-hidden border border-grey-600 rounded-lg flex items-stretch mt-5 first:mt-0'
      ref={container}
    >
      <div
        className='img-container h-full !flex-shrink-0  flex-[0_0_55%] bg-center bg-cover bg-no-repeat'
        style={{ backgroundImage: `url(${ASSETS.post})` }}
      >
        <video
          src={videoSrc}
          poster={data?.video.thumbnail || ''}
          controls
          className={classNames('h-[100%] w-full bg-black', 'object-contain')}
          loop
          disablePictureInPicture
          controlsList='nodownload noplaybackrate'
          onLoadedData={handleLoadData}
          ref={videoRef}
        ></video>
      </div>
      <div className='post-info flex-[0_0_45%]  flex flex-col bg-white relative'>
        <div className='flex-grow h-0 overflow-auto px-[21px] py-[23px] scroll-view '>
          <div className='absolute right-4 top-4'>
            <div className='menu relative'>
              <img
                src={ASSETS.menu}
                className='cursor-pointer'
                onClick={toggleMenu}
                alt=''
              />
              {open && (
                <ClickAwayListener onClickAway={closeMenu}>
                  <div className='menu absolute  w-[171px] shadow-lg rounded-[8px] bg-white right-0 flex flex-col gap-[2px] overflow-hidden'>
                    <div
                      className='pl-5 pr-2 flex items-center gap-[9px] py-3 hover:bg-grey-100 cursor-pointer'
                      onClick={handleSaveProfile}
                    >
                      <img src={ASSETS.bookmark} alt='' />
                      <p className='text-base font-medium'>
                        {!saved ? 'Save profile' : 'Unsave profile'}
                      </p>
                    </div>
                    <div
                      className='pl-5 pr-2 flex items-center gap-[9px] py-3 hover:bg-grey-100 cursor-pointer'
                      onClick={handleReport}
                    >
                      <img src={ASSETS.flag} alt='' />
                      <p className='text-base font-medium'>Report profile</p>
                    </div>
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </div>
          {data && (
            <div className=''>
              <p className='2xl:text-xl text-lg font-bold'>
                <span
                  className='cursor-pointer'
                  // onClick={() => setApplicationId('')}
                >
                  Received Applications -&gt;
                </span>{' '}
                <Link to={`/other-job-seeker/${data.user_id}`}>
                  {data?.user?.first_name} {data?.user?.last_name}
                </Link>
              </p>
              {data?.cv_url && (
                <div className='attachment mt-4'>
                  <p className='2xl:text-lg text-base font-medium leading-7'>
                    Resume
                  </p>
                  <a
                    href={data.cv_url}
                    download
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className='border-[0.5px] mt-2 border-placeholderColor 2xl:h-[65px] h-[50px] rounded-lg flex items-center gap-2 px-[20px] bg-white'>
                      <img
                        src={
                          data?.cv_type === 'application/pdf'
                            ? ASSETS.pdf
                            : ASSETS.word
                        }
                        className='w-6 2xl:w-auto'
                        alt=''
                      />
                      <p className='text-sm font-medium'>
                        {data?.user_name} ({data?.user.job_title}).
                        {data?.cv_type === 'application/pdf' ? 'pdf' : 'doc'}
                      </p>
                    </div>
                  </a>
                </div>
              )}
              {data?.cover_url && (
                <div className='attachment mt-[11px]'>
                  <p className='2xl:text-lg text-base font-medium leading-7'>
                    Cover Letter/ Attachment
                  </p>
                  <a
                    href={data.cover_url}
                    download
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className='border-[0.5px] mt-2 border-placeholderColor 2xl:h-[65px] h-[50px] rounded-lg flex items-center gap-2 px-[20px] bg-white'>
                      <img
                        src={
                          data?.cover_type === 'application/pdf'
                            ? ASSETS.pdf
                            : ASSETS.word
                        }
                        className='w-6 2xl:w-auto'
                        alt=''
                      />
                      <p className='text-sm font-medium'>
                        {data?.user_name} ({data?.user.job_title}).
                        {data?.cover_type === 'application/pdf' ? 'pdf' : 'doc'}
                      </p>
                    </div>
                  </a>
                </div>
              )}
              <div className='flex items-center justify-between mt-6'>
                <p className='text-base font-medium'>Expected Salary Range</p>
                <p className='text-base font-medium'>
                  ${convertNumberToK(data?.salary_range_from || 0)}-$
                  {convertNumberToK(data?.salary_range_to || 0)}
                </p>
              </div>
              <div className='mt-[21px] mb-[14px] h-[1px] bg-grey-600'></div>
              <div className='flex flex-col gap-[18px]'>
                <p className='text-base font-semibold leading-5'>
                  Work Authorization
                </p>
                <div className='flex justify-between items-center'>
                  <p className='text-base font-medium leading-5'>
                    Are you authorized to work in the United States?
                  </p>
                  <p className='text-base font-medium leading-5 '>
                    {data?.application_detail.isUSAuthorized}
                  </p>
                </div>
                <div className='flex justify-between items-center'>
                  <p className='text-base font-medium leading-5'>
                    Do you now or in the future require a working visa
                    sponsorship?
                  </p>
                  <p className='text-base font-medium leading-5 '>
                    {data?.application_detail.isSponsorWorkVisa}
                  </p>
                </div>
              </div>
              <div className='mt-[21px] mb-[14px] h-[1px] bg-grey-600'></div>
              <div className='flex flex-col gap-[18px]'>
                <p className='text-base font-semibold leading-5'>
                  Diversty & Inclusion
                </p>
                <div className='flex justify-between items-center'>
                  <p className='text-base font-medium leading-5'>
                    Do you have any disability?
                  </p>
                  <p className='text-base font-medium leading-5 '>
                    {data?.application_detail.isDisability}
                  </p>
                </div>
                <div className='flex justify-between items-center'>
                  <p className='text-base font-medium leading-5'>
                    Do you identify as a vetaran?
                  </p>
                  <p className='text-base font-medium leading-5 '>
                    {data?.application_detail.isVeteran}
                  </p>
                </div>
                <div className='flex justify-between items-center'>
                  <p className='text-base font-medium leading-5'>
                    What is your racial background?
                  </p>
                  <p className='text-base font-medium leading-5 '>
                    {data?.application_detail.isRacialBackground}
                  </p>
                </div>
              </div>
              <div className='mt-[21px] mb-[14px] h-[1px] bg-grey-600'></div>
              <div className='flex flex-col gap-[18px]'>
                <p className='text-base font-semibold leading-5 '>
                  Employment History
                </p>
                <div className='flex justify-between items-center'>
                  <p className='text-base font-medium leading-5'>
                    Have you previously worked for our company?
                  </p>
                  <p className='text-base font-medium leading-5  '>
                    {data?.application_detail.isFormerEmployee}
                  </p>
                </div>
                <div className='flex justify-between items-center'>
                  <p className='text-base font-medium leading-5'>
                    Have you worked for any affiliate companies?
                  </p>
                  <p className='text-base font-medium leading-5  '>
                    {data?.application_detail.isAffiliateCompany}
                  </p>
                </div>
              </div>
              <div className='mt-[21px] mb-[14px] h-[1px] bg-grey-600'></div>
              <div className='flex flex-col gap-[18px]'>
                <p className='text-base font-semibold leading-5 '>
                  Referred By
                </p>
                <div className='flex justify-between items-center'>
                  <p className='text-base font-medium leading-5'>
                    Were you referred by someone from our company?
                  </p>
                  <p className='text-base font-medium leading-5  '>
                    {data?.application_detail.isReferred}
                  </p>
                </div>
                {data?.application_detail.is_referral_name && (
                  <div className='flex justify-between items-center'>
                    <p className='text-base font-medium leading-5'>
                      Referrer name
                    </p>
                    <p className='text-base font-medium leading-5 '>
                      {data?.application_detail.is_referral_name}
                    </p>
                  </div>
                )}
                {data?.application_detail.is_referral_email && (
                  <div className='flex justify-between items-center'>
                    <p className='text-base font-medium leading-5'>
                      Referrer email
                    </p>
                    <p className='text-base font-medium leading-5  '>
                      {data?.application_detail.is_referral_email}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SelectedApplication: FC<IProps> = ({ selectedApplicationId }) => {
  const { receivedJobs, selectedJobTitle } = useSelector(
    (state: RootState) => state.myjobs
  );
  return (
    <>
      {receivedJobs &&
        selectedJobTitle &&
        receivedJobs[selectedJobTitle].map((item, index) => (
          <JobApplication
            key={item.application_id}
            receivedApplication={item}
            selectedApplicationId={selectedApplicationId}
            index={index}
          />
        ))}
    </>
  );
};

export default SelectedApplication;
