import { IApiResponse, IApplyJob, ICreateJob, IReportedJob } from 'types/api';
import api from './index';
import {
  IApplication,
  IApplicationData,
  IAppliedJob,
  IJob,
  IJobData,
  IJobTitle,
  IReceivedJobs,
  IReportedCompanies,
  ISavedJob,
  ITag,
  IViewedJobs,
} from 'types/interfaces';

export const getJobByid = (id: string) =>
  api.get<IApiResponse<IJob>>(`/v1/job/${id}`);

export const getTags = (search: string) =>
  api.get<
    IApiResponse<{ tags: ITag[]; currentPage: number; totalPages: number }>
  >(`/v1/job/tags?search=${search}`);

export const getJobTitles = (search: string) =>
  api.get<
    IApiResponse<{
      job_titles: IJobTitle[];
      totalPages: number;
      currentPage: number;
    }>
  >(`/v1/job/title?search=${search}`);

export const createJob = (data: ICreateJob) =>
  api.post<IApiResponse<IJobData>>('/v1/job', data);

export const getJobById = (job_id: string) =>
  api.get<IApiResponse<IJob>>(`/v1/job/${job_id}`);

export const uploadJobDocs = (data: FormData) =>
  api.post<IApiResponse<{ application_id: string }>>('/v1/job/upload', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const applyJob = (data: IApplyJob, id: string) =>
  api.post<IApiResponse<IApplication>>(`/v1/job/apply/${id}`, data);

export const followCompany = (id: string) =>
  api.post('/v1/user/following', { follow_to: id });

export const getReportedJobs = () =>
  api.get<IApiResponse<IReportedJob[]>>('/v1/user/block?type=job_report');

export const getReportedCompanies = () =>
  api.get<IApiResponse<IReportedCompanies[]>>(
    '/v1/user/block?type=company_report'
  );

export const saveJob = (id: string) => api.post('/v1/job/save', { job_id: id });

export const getSavedJobs = () =>
  api.get<IApiResponse<ISavedJob[]>>('/v1/job/save');

export const appliedJobs = () =>
  api.get<IApiResponse<IAppliedJob[]>>('/v1/user/applied');

export const viewesJobs = () =>
  api.get<IApiResponse<IViewedJobs[]>>('/v1/user/viewed');

export const receivedJobApplications = () =>
  api.get<IApiResponse<IReceivedJobs[]>>('/v1/user/received');

export const getJobApplicationById = (id: string) =>
  api.get<IApiResponse<IApplicationData>>(`/v1/job/application/${id}`);

export const viewJob = (id: string) =>
  api.post('/v1/job-view', {
    job_id: id,
  });
