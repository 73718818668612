import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

import ASSETS from 'assets';
import { t } from 'i18next';

interface IProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const Search: FC<IProps> = ({ value, setValue }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [focused, setFocused] = useState(false);

  const handleClick = () => {
    if (location.pathname !== '/explore/search-finds')
      navigate('/explore/search-finds');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (focused && value) {
      handleClick();
    }
    // eslint-disable-next-line
  }, [value, focused]);

  return (
    <Container className='relative 2xl:max-w-[600px] max-w-[500px] '>
      <div className='input border  border-grey-600 w-full 2xl:h-10 h-9 2xl:px-3 px-2 rounded-3xl overflow-hidden items-center flex gap-1 '>
        <img src={ASSETS.search} className='w-5 2xl:w-auto' alt='' />
        <input
          type='search'
          placeholder={t('Search people, companies or jobs')}
          className='flex-grow h-full placeholder:text-placeholderColor 2xl:text-base text-sm outline-none border-none bg-transparent'
          onChange={handleChange}
          value={value}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </div>
    </Container>
  );
};

export default Search;

const Container = styled.div`
  flex-grow: 1;
`;
