import {
  firebaseAPIKey,
  firebaseAppId,
  firebaseAuthDomain,
  firebaseMeasurementId,
  firebaseMessagingSenderId,
  firebaseProjectId,
  firebaseStorageBucket,
  firebaseVapidKey,
} from 'app.config';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { updateDeviceToken } from 'http/authService';

const firebaseConfig = {
  apiKey: firebaseAPIKey,
  authDomain: firebaseAuthDomain,
  projectId: firebaseProjectId,
  storageBucket: firebaseStorageBucket,
  messagingSenderId: firebaseMessagingSenderId,
  appId: firebaseAppId,
  measurementId: firebaseMeasurementId,
};

export const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

// Request permission for push notifications

export const getFirebaseToken = async (): Promise<string> => {
  return getToken(messaging, {
    vapidKey: firebaseVapidKey,
  });
};

navigator.serviceWorker
  .register('/firebase-messaging-sw.js')
  .then(registration => {
    console.log('Service Worker registered with scope:', registration.scope);
  })
  .catch(err => {
    console.error('Service Worker registration failed:', err);
  });
