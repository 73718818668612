import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import DialogLayout from 'Dialogs/DialogLayout';
import ASSETS from 'assets';

const ImagePreview = () => {
  const dispatch = useDispatch<Dispatch>();

  const { imagePreview } = useSelector((state: RootState) => state.utils);

  const handleClose = () => {
    dispatch.utils.closeImagePreview();
  };
  return (
    <DialogLayout open={imagePreview.open}>
      <div className='max-w-[70vw] h-[70vh] bg-white rounded-lg overflow-hidden relative'>
        <img src={imagePreview.imgURL} className='h-full' alt='' />
        <div
          className='absolute p-1 right-2 top-2 bg-white border border-grey-400 rounded-full cursor-pointer'
          role='button'
          onClick={handleClose}
        >
          <img src={ASSETS.cross} className='w-5' alt='' />
        </div>
      </div>
    </DialogLayout>
  );
};

export default ImagePreview;
